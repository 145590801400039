import firebase from '../firebase/firebase';
import axios from 'axios';

const baseUrl2 = "https://gps.chekcar.mx/admin/";
const baseUrl = "https://gps.chekcar.mx/api/";
const baseUrl3 = "https://api.chekcar.mx/v1/";
const headers = {
  'Content-Type': 'application/json',
};

const hereApiKey = "kUsKaAJJxXkSBces1hmFjNrIPBKRjZyXezk66tCyhXk";

class Services {
  async getCollection(collection) {
    return await firebase.firestore().collection(collection).get();
  }

  async getCollectionWhere(collection, w1, w2) {
    return await firebase.firestore().collection(collection).where(w1, "==", w2).get();
  }

  async addDocument(collection, data) {
    return await firebase.firestore().collection(collection).add(data);
  }

  async addDocById(collection, id, data) {
    return await firebase.firestore().collection(collection).doc(id).set(data);
  }

  async getDocumentById(collection, id) {
    return await firebase.firestore().collection(collection).doc(id).get();
  }

  async getDocumentWhere(collection, w1, w2) {
    return await firebase.firestore().collection(collection).where(w1, "==", w2).limit(1).get();
  }

  async updateDocument(collection, id, data) {
    return await firebase.firestore().collection(collection).doc(id).update(data);
  }

  async uploadFile(path, _file) {
    try {
      const file = await firebase.storage().ref().child(path).put(_file);
      const fileUrl = await file.ref.getDownloadURL();
      
      return fileUrl.toString();
    } catch (error) {
      console.log(error);
      return 'error';
    }
  }

  async deleteFile(path) {
    return await firebase.storage().ref().child(path).delete();
  }

  async delete(collection, id) {
    return await firebase.firestore().collection(collection).doc(id).delete();
  }

  async post(url = "", data = {}, withBaseUrl = true) {
    const _url = withBaseUrl ? baseUrl + url : url;
    return await axios.post(_url, data, {headers: headers});
  }

  async post2(url = "", data = {}, ) {
    return await axios.post(baseUrl2 + url, data, {headers: headers});
  }

  async get(url = "", withBaseUrl = true) {
    const _url = withBaseUrl ? baseUrl + url : url;

    return await axios.get(_url);
  }

  async get2(url = "") {
    return await (await axios.get(baseUrl2 + url)).data;
  }

  async geoCoder(lat, lng) {
    return await axios.get(`https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?xnlp=CL_JSMv3.1.21.3&apikey=${hereApiKey}&prox=${lat}%2C${lng}%2C300&mode=retrieveAddresses&maxresults=1`);
  }

  async historicalData(id, minutes){
    return await axios.post("https://historico.chekcar.mx/get_data", {"id": id, "minutes": minutes});
  }

  async post3(url, data = {}, token) {
    return await axios.post(baseUrl3 + url, data, {headers: token ? {...headers, "Authorization": `Bearer ${token}`} : headers});
  }
}

export default new Services;