import { CircularProgress } from '@material-ui/core';
import React from 'react';

const FullScreenLoader = () => {
  return (
    <div style={{
      position: "absolute",
      left: "50%",
      top: "50%",
      WebkitTransform: "translate(-50%, -50%)",
      transform: "translate(-50%, -50%)"
    }}>
      <CircularProgress />
    </div>
  )
}

export default FullScreenLoader;
