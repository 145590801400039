import React from 'react';
import { Box } from '@material-ui/core';

const Container = ({children}) => {
  return (
    <div style={{padding: 20}}>
      <div style={{width: "100%"}}>
        { children }
      </div>
    </div>
  )
}

export default Container
