import React from "react";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BuildIcon from "@material-ui/icons/Build";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import WarningIcon from "@material-ui/icons/Warning";
import GpsOffIcon from "@material-ui/icons/GpsOff";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import PortableWifiOffIcon from "@material-ui/icons/PortableWifiOff";
import SendIcon from "@material-ui/icons/Send";
import GpsNotFixedIcon from "@material-ui/icons/GpsNotFixed";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BatteryUnknownIcon from "@material-ui/icons/BatteryUnknown";
import ErrorIcon from "@material-ui/icons/Error";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import NoMeetingRoomIcon from "@material-ui/icons/NoMeetingRoom";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import PowerIcon from "@material-ui/icons/Power";
import SpeedIcon from "@material-ui/icons/Speed";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import { Avatar } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";

const iconsTypeByEvent = [
  {
    id: 2,
    descripcion: "Arrastre de Vehículo", 
    icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>)
  },
  {
    id: 20,
    descripcion: "Motor Encendido", 
    icon: (
    <Avatar style={{ backgroundColor: "green" }}>
      <PowerSettingsNewIcon />{" "}
    </Avatar>)
  },
  {
    id: 21,
    descripcion:"Motor Apagado", 
    icon:(
    <Avatar style={{ backgroundColor: "red" }}>
      <PowerSettingsNewIcon />
    </Avatar>)
  },
  {id: 0, descripcion: "Posición Actualizada", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <LocationOnIcon />
    </Avatar>)
  },
  {id:62, descripcion: "I-Button insertado", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <RadioButtonCheckedIcon />
    </Avatar>)
  },
  {id: 44, descripcion: "I-Button retirado", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <RadioButtonUncheckedIcon />
    </Avatar>)
  },
  {id: 27, descripcion: "Freno Fuerte", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  )},
  {id: 29, descripcion: "Exceso de velocidad", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  )},
  {id:32, descripcion: "Fin de Ralentí", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  )},
  {id: 31, descripcion: "Inicia Ralentí", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  )},
  {id: 37, descripcion: "Motor: Violación de velocidad de Vehículo", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  )},
  {id:54, descripcion:"Sobre RPM", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  )},
  {id:55, descripcion: "Calibración de RPM", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  )},
  {id:56, descripcion: "Calibración de Odómetro", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <LocalGasStationIcon />
    </Avatar>
  )},
  {id:58, descripcion:"Temperatura alta", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <WhatshotIcon />
    </Avatar>
  )},
  {id:59, descripcion:"Temperatura baja", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <AcUnitIcon />
    </Avatar>
  )},
  {id:60, descripcion: "Temperatura normal", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <CheckCircleIcon />
    </Avatar>
  )},
  {id:61, descripcion: "Mantenimiento", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <BuildIcon />
    </Avatar>
  )},
  {id:26, descripcion:"Aceleración súbita", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  )},
  {id:34, descripcion: "Movimiento", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <DirectionsCarIcon />
    </Avatar>
  )},
  {id:2006, descripcion: "Descarga Combustible", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <LocalGasStationIcon />
    </Avatar>
  )},
  {id:53, descripcion: "Avance neutro", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <DragHandleIcon />
    </Avatar>
  )},
  {id:16, descripcion: "Choque", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <WarningIcon />
    </Avatar>
  )},
  {id:45, descripcion: "Motor encendido, auto en espera", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <PowerSettingsNewIcon />
    </Avatar>
  )},
  {id:50, descripcion:"Detenido demasiado tiempo", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <PowerSettingsNewIcon style={{ backgroundColor: "red" }} />
    </Avatar>
  )},
  {id: 67, descripcion: "Recuperación de Señal GPS", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <GpsOffIcon />
    </Avatar>
  )},
  {id:1005, descripcion: "Alerta vigilar Ignición abierta", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <ErrorOutlineIcon />
    </Avatar>
  )},
  {id:17, descripcion: "Colisión", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <WarningIcon />
    </Avatar>
  )},
  {id:1006, descripcion:"Conexión de equipo", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SettingsEthernetIcon />
    </Avatar>
  )},
  {id:1007, descripcion: "Desconexión de equipo", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <PortableWifiOffIcon />
    </Avatar>
  )},
  {id:43, descripcion:"Comando RS232 recibido", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SendIcon />
    </Avatar>
  )},
  {id:7, descripcion:"Antena GPS desconectada", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <GpsNotFixedIcon />
    </Avatar>
  )},
  {id:1004, descripcion:"Alerta de vigilar en movimiento", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <VisibilityIcon />
    </Avatar>
  )},
  {id:25, descripcion:"Batería Interna Desconectada", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <BatteryUnknownIcon />
    </Avatar>
  )},
  {id:1, descripcion:"Botón de pánico", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <ErrorIcon />
    </Avatar>
  )},
  {id:19,descripcion:"Entrada a Ruta", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <LinearScaleIcon />
    </Avatar>
  )},
  {id:71, descripcion:"Ruptura de convoy", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <BrokenImageIcon />
    </Avatar>
  )},
  {id:10, descripcion:"Entrada a geocerca", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <ExitToAppIcon />
    </Avatar>
  )},
  {id:18,descripcion:"Salida de Ruta",icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <LinearScaleIcon />
    </Avatar>
  )},
  {id:73, descripcion:"Desactivación Entrada 1", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <NotInterestedIcon />
    </Avatar>
  )},
  {id:74, descripcion:"Activación Entrada 3", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <PowerIcon />
    </Avatar>
  )},
  {id:75, descripcion:"Desactivación Entrada 3", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <NotInterestedIcon />
    </Avatar>
  )},
  {id:1008, descripcion:"Apertura de Puerta", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <MeetingRoomIcon />
    </Avatar>
  )},
  {id: 1009, descripcion:"Cierre de Puerta", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <NoMeetingRoomIcon />
    </Avatar>
  )},
  {id:28, descripcion:"Vuelta repentina", icon: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <ReplayIcon />
    </Avatar>
  )},
];

export default iconsTypeByEvent;
