import React, { useState } from 'react';
import {
  Paper, Typography, TextField,
  Grid, Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loader from './../../../components/Loader';

const useStyles = makeStyles(theme => ({
  root: {
    height: '270px',
    width: '100%',
    maxWidth: '330px'
  },
  form: {
    fontFamily: 'roboto',
    fontSize: '12px',
    width: '100%',
    height: '100%',
    padding: theme.spacing(3),
    boxSizing: 'border-box',
    '& > *': {
      marginBottom: theme.spacing(2)
    },
    '&.loading': {
      visibility: 'hidden'
    }
  }
}));

const LoginForm = ({ onSubmit, loading }) => {
  const classes = useStyles();
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit && onSubmit(email, password);
  }

  return (
    <Paper className={classes.root}>
      { loading? <Loader /> : null }
      <form
        onSubmit={handleSubmit}
        className={`${ classes.form } ${ loading? 'loading' : '' }`}>
        <Typography
          variant="h5"
          component="h1">
          INICIA SESIÓN
        </Typography>
        <TextField
          type="text"
          variant="filled"
          fullWidth
          label="Usuario"
          onChange={e => setEmail(e.target.value)}/>
        <TextField
          type="password"
          variant="filled"
          fullWidth
          label="Contraseña"
          onChange={e => setPassword(e.target.value)}/>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            type="submit">
            INICIAR
          </Button>
        </Grid>
      </form>
    </Paper>
  );
};

export default LoginForm;