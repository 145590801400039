import React from 'react'
import Div100vh from 'react-div-100vh'
import AppBar from '../../components/AppBar/AppBar'

const Share = () => {
  return (
    <Div100vh>
      <AppBar title="Compartir rastreo" />
    </Div100vh>
  )
}

export default Share
