import React, { useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  Card,
  TextField,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import FullScreenLoaderView from "../../components/FullScreenLoaderView/FullScreenLoaderView";
import AppBar from "../../components/AppBar/AppBar";
import Container from "../../components/Container/Container";
import Services from "../../services";
import { useToken } from "../../hooks/useToken";

const myStorage = window.localStorage;

const GeofencesView = ({ history }) => {
  const [geofences, setGeofences] = useState([]);
  const { setGeofences: setGeofencesHook } = useToken();

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      if (mounted) {
        try {
          const { id } = JSON.parse(myStorage.getItem("usuarioChekcar"));

          const response = await Services.get2("geocerca?idUsuario=" + id);

          if (response.geocercas) {
            setGeofences(response.geocercas);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };

    getData();

    return () => {
      mounted = false;
    };
  }, []);

  const toMap = (geofence) => {
    setGeofencesHook(geofence);
    history.push({
      pathname: "/geocercas/mapa",
      state: geofence,
    });
  };

  return (
    <Div100vh>
      <AppBar title="Geocercas" />
      <Container>
        <TextField
          size="small"
          variant="filled"
          fullWidth
          label="Buscar"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Card
          style={{
            marginTop: 20,
            paddingBottom: 10,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          {geofences
            .filter((c) =>
              c.nombre.toUpperCase().includes(search.toUpperCase())
            )
            .map((g, index) => (
              <div key={index}>
                <ListItem onClick={() => toMap(g)}>
                  <ListItemText primary={g.nombre} />
                  <ListItemIcon>
                    <IconButton style={{ color: "#1388ca" }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>
                <Grid
                  container
                  spacing={2}
                  style={{ marginLeft: 10, marginTop: -20 }}
                >
                  <Grid item>
                    <Typography style={{ fontSize: 14, color: "grey" }}>
                      color:{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div
                      style={{
                        backgroundColor: g.color,
                        height: 25,
                        width: 25,
                        borderRadius: 20,
                      }}
                    ></div>
                  </Grid>
                </Grid>
              </div>
            ))}
        </Card>
      </Container>
      <FullScreenLoaderView open={loading} />
    </Div100vh>
  );
};

export default GeofencesView;
