import { Typography } from '@material-ui/core'
import React from 'react'
import Div100vh from 'react-div-100vh'
import AppBar from '../../components/AppBar/AppBar'

const FinishShareTrackingView = () => {
  return (
    <Div100vh>
      <AppBar title="Tiempo expirado" showIconParam={false} />
      <div>
        <Typography align="center" style={{marginTop: "42vh"}}>
          EL RASTREO HA TERMINADO
        </Typography>
      </div>
    </Div100vh>
  )
}

export default FinishShareTrackingView
