import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useToken } from "../../hooks/useToken";

const homePath = "home/";

const CheckRoute = ({ children }) => {
  const history = useHistory();
  const { userAuth } = useToken();

  useEffect(() => {
    checkRoute(history?.location?.pathname);
  }, []);

  useEffect(() => {
    const unsub = history.listen((location) => {
      checkRoute(location?.pathname);
    });
    return () => unsub();
  }, [history, userAuth]);

  const checkRoute = (pathname) => {
    if (userAuth && pathname === "/") {
      history.push(homePath + userAuth);
    }
  };

  return <div>{children}</div>;
};

export default CheckRoute;
