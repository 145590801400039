import React, { useState } from "react";
import SpeedIcon from "@material-ui/icons/Speed";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CloseIcon from "@material-ui/icons/Close";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import moment from "moment";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  IconButton,
} from "@material-ui/core";
import iconsType from "../Historical/IconsType";
import DialogStatus from "./DialogStatus";
import Alert from "../../components/Alert";

const BottomSheets = ({ isShared = false, open, car, onClose, hoursUtc }) => {
  const [openDialogStatus, setOpenDialogStaus] = useState(false);
  const [message, setMessage] = useState("");

  return (
    open && (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "white",
          height: "22vh",
          zIndex: 999,
        }}
      >
        <ListItem style={{ marginTop: -10, marginBottom: -20 }}>
          <ListItemIcon>
            {car.NombreEvento === "-" ? (
              <DriveEtaIcon style={{ color: "#1388ca" }} />
            ) : (
              iconsType[car.NombreEvento]
            )}
          </ListItemIcon>
          <ListItemText primary={car.nombre} secondary={car.modelo} />
          <IconButton onClick={() => onClose()}>
            <CloseIcon style={{ color: "#1388ca" }} />
          </IconButton>
        </ListItem>
        <hr style={{ width: "98%" }} />
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            md={6}
            style={{ marginTop: -10, marginBottom: -20 }}
          >
            <ListItem>
              <ListItemIcon>
                <SpeedIcon style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText
                primary={parseFloat(car.speed).toFixed(0) + " Km/h"}
              />
            </ListItem>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            style={{ marginTop: -10, marginBottom: -20 }}
          >
            <ListItem>
              <ListItemIcon>
                <CalendarTodayIcon style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText
                primary={moment(car.timestamp)
                  .add(-hoursUtc, "hours")
                  .format("DD/MM/YYYY")}
              />
            </ListItem>
          </Grid>
          <Grid item xs={6} md={6} style={{ marginTop: -10 }}>
            <ListItem>
              <ListItemIcon>
                <ScheduleIcon style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText
                primary={moment(car.timestamp)
                  .add(-hoursUtc, "hours")
                  .format("hh:mm a")}
              />
            </ListItem>
          </Grid>
          {
            !isShared && (
              <Grid item xs={6} md={6} style={{ marginTop: -10 }}>
                <ListItem onClick={() => setOpenDialogStaus(true)}>
                  <ListItemIcon>
                    <PowerSettingsNewIcon
                      style={{ color: car.ioStatus[9] === "t" ? "red" : "green" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      car.ioStatus[9] === "t"
                        ? "Habilitar unidad"
                        : "Inhabilitar unidad"
                    }
                  />
                </ListItem>
              </Grid>
            )
          }
        </Grid>
        <DialogStatus
          open={openDialogStatus}
          car={car}
          onCancel={(message) => {
            setOpenDialogStaus(false);

            if (message) {
              setMessage(message);
            }
          }}
        />
        <Alert
          open={Boolean(message)}
          onClose={() => setMessage("")}
          severity="success"
          message={message}
        />
      </div>
    )
  );
};

export default BottomSheets;
