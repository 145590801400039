import React from "react";
import "./styles.css";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import theme from "./components/Theme";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  HomeView,
  LogInView,
  PrivacyPolicyView,
  ContinuosTrackingView,
  MapContinuosTrackingView,
  HistoricalView,
  MapHistoricalView,
  UnitsView,
  DetailUnitView,
  PointsView,
  MapPointsView,
  UnitsGroupsView,
  GeofencesView,
  MapGeofencesView,
  PonitsGroupView,
  MapPointsGroupsView,
  ShareTrackingView,
  ShareView,
  ShareContinuosTrackingView,
  FinishShareTrackingView
} from "./views";
import { useToken } from "./hooks/useToken";
import Drawer from "./components/Drawer";
import CheckRoute from "./Components/CheckRoute/CheckRoute";
import ConfigEventosView from "./views/Notifications/Eventos";
import ALLUnitsUnitView from "./views/Notifications/AllUnitsView";
const bodyStyles = {
  margin: 0,
  padding: 0,
  overflow: "hidden",
  height: "100vh",
  width: "100wh",
};

const urlsNoAuth = [
  "/rastreo-continuo/terminar",
  "/rastreo-continuo/compartir/",
  "/privacy-policy",
];

export default () => {
  const { token2, showDrawer } = useToken();
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleIsOpen = () => setIsOpen((value) => !value);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Router>
          <CheckRoute>
            {window.location.pathname !== "/login" &&
              !urlsNoAuth.some((url) =>
                window.location.pathname.includes(url)
              ) &&
              !token2 && <Redirect to="/login" />}
            <div style={bodyStyles}>
              {token2 && showDrawer && (
                <Drawer isOpen={isOpen} toggleIsOpen={toggleIsOpen} />
              )}
              <Switch>
                <Route exact path="/login" component={LogInView} />
                <Route
                  exact
                  path="/privacy-policy"
                  component={PrivacyPolicyView}
                />
                <Route exact path="/home/:id" component={HomeView} />
                <Route
                  exact
                  path="/rastreo-continuo"
                  component={ContinuosTrackingView}
                />
                <Route
                  exact
                  path="/rastreo-continuo/mapa"
                  component={MapContinuosTrackingView}
                />
                <Route
                  exact
                  path="/rastreo-continuo/compartir/:id&:sh"
                  component={ShareContinuosTrackingView}
                />
                <Route
                  exact
                  path="/rastreo-continuo/terminar"
                  component={FinishShareTrackingView}
                />
                <Route exact path="/geocercas" component={GeofencesView} />
                <Route
                  exact
                  path="/geocercas/mapa"
                  component={MapGeofencesView}
                />
                <Route
                  exact
                  path="/grupos-puntos"
                  component={PonitsGroupView}
                />
                <Route
                  exact
                  path="/grupos-puntos/mapa"
                  component={MapPointsGroupsView}
                />
                <Route
                  exact
                  path="/grupos-unidades"
                  component={UnitsGroupsView}
                />
                <Route exact path="/puntos" component={PointsView} />
                <Route exact path="/puntos/mapa" component={MapPointsView} />
                <Route exact path="/historico" component={HistoricalView} />
                <Route
                  exact
                  path="/historico/mapa"
                  component={MapHistoricalView}
                />
                <Route exact path="/unidades" component={UnitsView} />
                <Route
                  exact
                  path="/unidades/detalle"
                  component={DetailUnitView}
                />
                <Route
                  exact
                  path="/compartir-rastreo"
                  component={ShareTrackingView}
                />
                <Route
                  exact
                  path="/compartir-rastreo/:idCar/:time"
                  component={ShareView}
                />
                <Route
                  exact
                  path="/compartir-rastreo"
                  component={ShareTrackingView}
                />
                <Route
                  exact
                  path="/compartir-rastreo/compartir/:id"
                  component={ShareView}
                />
                
                <Route
                  exact
                  path="/notificaciones-eventos"
                  component={ALLUnitsUnitView}
                />
                <Route
                  exact
                  path="/notificaciones"
                  component={ConfigEventosView}
                />
              </Switch>
            </div>
          </CheckRoute>
        </Router>
      </ThemeProvider>
    </div>
  );
};
