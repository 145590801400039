import Autobus from '../../cars/red/Autobus.png';
import Automovil from '../../cars/red/Automovil.png';
import Cajadecarga from '../../cars/red/Caja de carga.png';
import Camiondecemento from '../../cars/red/Camion de cemento.png';
import Camiondeliquido from '../../cars/red/Camion de liquido.png';
import Camiongrande from '../../cars/red/Camion grande.png';
import Camionsincaja from '../../cars/red/Camion sin caja.png';
import Camion from '../../cars/red/Camion.png';
import Camioneta from '../../cars/red/Camioneta.png';
import Default from '../../cars/red/default.png';
import Furgoneta from '../../cars/red/Furgoneta.png';
import Furgonetamediana  from '../../cars/red/Furgoneta-Mediana.png';
import Generador  from '../../cars/red/Generador.png';
import Grua from '../../cars/red/Grua.png';
import Hatchbackmediano  from '../../cars/red/Hatchback mediano.png';
import hatchback  from '../../cars/red/hatchback.png';
import Maquinadeconstruccion from '../../cars/red/Maquina de construccion.png';
import MiniVan from '../../cars/red/MiniVan.png';
import Motoclasica from '../../cars/red/Moto clasica.png';
import Motocomercial from '../../cars/red/Moto comercial.png';
import Motodecampo from '../../cars/red/Moto de campo.png';
import Motodeportiva from '../../cars/red/Moto deportiva.png';
import Motoneta from '../../cars/red/Motoneta.png';
import PickUpdetrabajo from '../../cars/red/PickUp de trabajo.png';
import PickUpindustrial from '../../cars/red/PickUp industrial.png';
import PickUpmediana from '../../cars/red/PickUp mediana.png';
import pickup from '../../cars/red/pickup.png';
import PickUp from '../../cars/red/pickup.png';
import Plataforma from '../../cars/red/Plataforma.png';
import Sedanmediano from '../../cars/red/Sedan mediano.png';
import Sedan from '../../cars/red/Sedan.png';
import SUV from '../../cars/red/SUV.png';
import Trailer from '../../cars/red/Trailer.png';

const cars = {
  "Autobus": Autobus,
  "Automovil": Automovil, 
  "Cajadecarga": Cajadecarga, 
  "Camiondecemento": Camiondecemento, 
  "Camiondeliquido": Camiondeliquido,
  "Camiongrande": Camiongrande,
  "Camionsincaja": Camionsincaja, 
  "Camion": Camion,
  "Camioneta": Camioneta,  
  "default": Default,  
  "Furgoneta": Furgoneta, 
  "Furgonetamediana": Furgonetamediana, 
  "Generador": Generador,
  "Grua": Grua, 
  "Hatchbackmediano": Hatchbackmediano, 
  "hatchback": hatchback,
  "Hatchback": hatchback,
  "Maquinadeconstruccion": Maquinadeconstruccion,
  "MiniVan": MiniVan,
  "Motoclasica": Motoclasica,
  "Motocomercial": Motocomercial, 
  "Motodecampo": Motodecampo,
  "Motodeportiva": Motodeportiva, 
  "Motoneta": Motoneta,
  "PickUpdetrabajo": PickUpdetrabajo, 
  "PickUpindustrial": PickUpindustrial, 
  "PickUpmediana": PickUpmediana,
  "pickup": pickup,  
  "Plataforma": Plataforma,
  "Sedanmediano": Sedanmediano,
  "Sedan": Sedan,
  "SUV": SUV,
  "Trailer": Trailer,  
  "PickUp": PickUp
};

const HistoryMarker = (image, info, letter) => {
  return `<div>
    <div style="position: relative"> 
      <div style="position: absolute">
        <img src="${image}" style="height:15px" />
      </div>
      <div style="position: absolute">
          <div style="margin-left: 15px; background-color: #1388ca; width: 12px; height: 12px; border-radius: 50%">
              <label style="font-size: 10px; font-family: Roboto; color: white; position: fixed; margin-left: 4.5px;"> ${letter} </label>
          </div>
      </div>
    </div>
    <div style="padding-top: 16px">
      <label style="font-size: 10px; font-family: Roboto; color: white; padding: 1px;  background-color: #e52828; border-radius: 25px;"> ${info?.unidad} </label>
    </div>
  </div>`;
}

export default HistoryMarker;
