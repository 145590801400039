import React, { useState, useEffect } from "react";
import Div100vh from "react-div-100vh";
import SearchIcon from "@material-ui/icons/Search";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import AppBar from "../../components/AppBar/AppBar";
import Container from "../../components/Container/Container";
import Services from "../../services";
import Alert from "../../components/Alert";
import FullScreenLoaderView from "../../components/FullScreenLoaderView/FullScreenLoaderView";
import moment from "moment-timezone";
import { useToken } from "../../hooks/useToken";

const miStorage = window.localStorage;

const Historical = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [cars, setCars] = useState([]);
  const [error, setError] = useState("");
  const [loadingToMap, setLoadingToMap] = useState();
  const { hours, setHours } = useToken();


  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      if (mounted) {
        try {
          const user = JSON.parse(miStorage.getItem("usuarioChekcar"));

          const response = await Services.post2("prueba", { usuario: user.id });

          setCars(response.data.map((c) => ({ ...c, selected: false })));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };

    getData();

    return () => {
      mounted = false;
    };
  }, []);

  const toMap = async () => {
    if (loadingToMap) return;

    if (!cars.some((c) => c.selected)) {
      setError("Favor de seleccionar una unidad.");
      setTimeout(() => {
        setError("");
      }, 2500);

      return;
    }

    setLoadingToMap(true);
    
    let startDate = null;
    let endDate = null;

    if (hours && (hours === "24" || hours === "48")) {
      const tmp = hours === "24"
        ? moment().format("YYYY-MM-DD")
        : moment().add(-1, "day").format("YYYY-MM-DD");

      startDate = tmp + " 00:00:00";
      endDate = tmp + " 23:59:59";
    }

    setLoadingToMap(false);

    history.push({
      pathname: "/historico/mapa",
      state: {
        car: cars.find((c) => c.selected),
        hours: startDate ? null : hours,
        startDate,
        endDate,
        hora: hours
      },
    });
  };

  return (
    <Div100vh>
      <AppBar title="Histórico" />
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel>Horas - Dias</InputLabel>
              <Select
                label="Horas - Dias"
                value={hours}
                onChange={(event) => setHours(event.target.value)}
              >
                <MenuItem value={"2"}>Hace 2 horas</MenuItem>
                <MenuItem value={"4"}>Hace 4 horas</MenuItem>
                <MenuItem value={"8"}>Hace 8 horas</MenuItem>
                <MenuItem value={"24"}>Hoy</MenuItem>
                <MenuItem value={"48"}>Ayer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              label="Buscar"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{marginTop: 10}}>
            <Card style={{ maxHeight: "70vh", overflowY: "auto" }}>
              {cars
                .filter(
                  (c) =>
                    c.marca.toUpperCase().includes(search.toUpperCase()) ||
                    c.modelo.toUpperCase().includes(search.toUpperCase()) ||
                    c.nombre.toUpperCase().includes(search.toUpperCase())
                )
                .map((car) => (
                  <ListItem key={car?.id}>
                    <ListItemText
                      primary={car.nombre}
                      secondary={car.marca + " " + car.modelo}
                    />
                    <ListItemIcon>
                      <Radio
                        onChange={() =>
                          setCars(
                            cars.map((c) => ({
                              ...c,
                              selected: car?.id === c?.id,
                            }))
                          )
                        }
                        checked={car.selected}
                        color="primary"
                      />
                    </ListItemIcon>
                  </ListItem>
                ))}
            </Card>
          </Grid>
        </Grid>
        <Avatar
          style={{
            backgroundColor: "#1388ca",
            cursor: "pointer",
            position: "absolute",
            right: 0,
            bottom: 0,
            marginBottom: 10,
            marginRight: 10,
          }}
          onClick={toMap}
        >
          {loadingToMap ? (
            <CircularProgress size={20} style={{ color: "white" }} />
          ) : (
            <SearchIcon />
          )}
        </Avatar>
      </Container>
      <FullScreenLoaderView open={loading} />
      <Alert
        open={Boolean(error)}
        onClose={() => setError("")}
        severity="error"
        message={error}
      />
    </Div100vh>
  );
};

export default Historical;
