import googleMarker from '../../cars/red-marker.png';

// const getEvent = (data) => {
//   if(data?.image?.length > 3)
//     return `https://gps.chekcar.mx${data?.image}`
//   else return ""
// }
const GoogleMarker = (img) => {
  
  if(img != "")
    return `<div style="margin-top: -30px; margin-rigth: 30px ">
        <img src="${img}" style="height:25px" />
      </div>`
  else
    return `<div style="margin-top: -30px; margin-rigth: 30px ">
        <img src="${googleMarker}" style="height:25px" />
      </div>`
}

export default GoogleMarker;
