import React from 'react';
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core';

const FullScreenLoaderView = ({open}) => {
  return (
    <Dialog open={open}>
      <DialogContent style={{overflow: "hidden" , padding: 10}}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}

export default FullScreenLoaderView;
