import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import Services from "../../services";

const DialogStatus = ({ open, onCancel, car = {} }) => {
  const [loading, setLoading] = useState(false);

  const onChangeStatusCar = async () => {
    try {
      setLoading(true);

      const newStatus = car.ioStatus[9] === "f" ? 0 : 1;
      const myStorage = window.localStorage;
      const userJson = myStorage.getItem("usuarioChekcar");
      const user = JSON.parse(userJson);
      const response = await Services.post2("prueba/cambiar-estado-motor", {
        id: car.id,
        estado: newStatus,
        idUsuario: user.id,
        serviceId: 0,
      });

      if (response.data.success) {
        onCancel("Estado del motor cambiado.");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>¡Advertencia!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {car.ioStatus[9] === "t"
            ? "¿Seguro que desea habilidar la unidad: " + car.nombre + "?"
            : "¿Seguro que desea inhabilitar la unidad: " + car.nombre + " ?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={async () => {
            if (loading) return;
            await onChangeStatusCar();
          }}
          color="secondary"
          variant="contained"
          autoFocus
        >
          {loading ? (
            <CircularProgress style={{ color: "white" }} size={25} />
          ) : (
            "Aceptar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogStatus;
