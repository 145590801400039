import React, { useEffect, useRef, useState } from "react";
import Div100vh from "react-div-100vh";
import AppBar from "../../components/AppBar/AppBar";
import { useToken } from "../../hooks/useToken";
import ButtomSheets from "./ButtomSheets";

const platform = new H.service.Platform({
  apikey: "kUsKaAJJxXkSBces1hmFjNrIPBKRjZyXezk66tCyhXk",
});

const drawTypeGeofence = (dataGeofence, map) => {
  const { lat, lon, type, color, radio, vertices, top, left, bottom, right } =
    dataGeofence;
  let box;

  if (type === 1) {
    box = new H.map.Circle({ lat: lat, lng: lon }, radio, {
      style: {
        strokeColor: color,
        lineWidth: 2,
        fillColor: color,
      },
    });
  } else if (type === 2) {
    const lineString = new H.geo.LineString();

    vertices.forEach((v, i) => {
      if (i % 2 == 0) {
        lineString.pushPoint({ lat: v, lng: vertices[i + 1] });
      }
    });

    box = new H.map.Polygon(lineString, {
      style: {
        fillColor: color,
        strokeColor: color,
        lineWidth: 2,
      },
    });
  } else {
    const rec = new H.geo.Rect(top, left, bottom, right);

    box = new H.map.Rect(rec, {
      style: {
        fillColor: color,
        strokeColor: color,
        lineWidth: 2,
      },
    });
  }

  map.addObject(box);
  map.getViewModel().setLookAtData({ bounds: box.getBoundingBox() });
};

const MapGeofences = ({ location }) => {
  const mapRef = useRef();
  const [loading, setLoading] = useState(true);
  const [geofence, setGeofence] = useState(null);
  const { geofences: geofencesHook, setGeofences: setGeofencesHook } =
    useToken();

  useEffect(() => {
    let mounted = true;

    if (loading && location && mounted) {
      try {
        const H = window.H;
        const defaultLayers = platform.createDefaultLayers();
        const _map = new H.Map(
          mapRef.current,
          defaultLayers.raster.normal.map,
          {
            center: { lat: 21.6565042, lng: -99.333183 },
            zoom: 5.0,
            pixelRatio: window.devicePixelRatio || 1,
          }
        );

        const _behavior = new H.mapevents.Behavior(
          new H.mapevents.MapEvents(_map)
        );
        const _ui = H.ui.UI.createDefault(_map, defaultLayers, "es-ES");

        _ui.getControl("mapsettings").setAlignment("top-left");
        _ui.getControl("zoom").setAlignment("top-left");
        _ui.getControl("scalebar").setVisibility(false);

        setGeofence(location.state);
        drawTypeGeofence(location.state, _map);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    return () => {
      mounted = false;
    };
  }, [location, loading]);

  return (
    <Div100vh>
      <AppBar title="Geocerca" />
      <div style={{ height: "94vh" }}>
        <div ref={mapRef} />
      </div>
      <ButtomSheets geofence={geofence} />
    </Div100vh>
  );
};

export default MapGeofences;
