import React, { useState, useEffect } from 'react';
import Div100vh from "react-div-100vh";
import { 
  TextField, Grid, Card, ListItem, ListItemIcon, ListItemText,
  FormControl, InputLabel, Select, MenuItem, Switch, Avatar
} from '@material-ui/core';
import AppBar from '../../components/AppBar/AppBar';
import Container from '../../components/Container/Container';
import Services from '../../services';
import FullScreenLoaderView from '../../components/FullScreenLoaderView/FullScreenLoaderView';
import firebase from '../../firebase/firebase';
import Alert from "../../components/Alert";
import { Share } from '@material-ui/icons';

const miStorage = window.localStorage;

const ShareTracking = ({history}) => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [cars, setCars] = useState([]);
  const [time, setTime] = useState("15");
  const [error, setError] = useState("");

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      if(mounted) {
        try {
          const user = JSON.parse(miStorage.getItem("usuarioChekcar"));

          const response = await Services.post(
            "https://gps.chekcar.mx/admin/prueba",
            { usuario: user.id },
            false
          );
      
          setCars(response.data.map((c) => ({...c, selected: false})));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    getData();

    return () => { mounted = false }
  }, []);

  const toShare = async () => {
    if(!cars.some(c => c.selected)) {
      setError("Favor de seleccionar una unidad.");
      setTimeout(() => {
        setError("");
      }, 2500);

      return; 
    }

    const user = JSON.parse(localStorage.getItem("usuarioChekcar"));

    const doc = await Services.addDocument("restreoCompartido", {
      idsCar: cars.filter(c => c.selected).map(c => c.id),
      timeShare: time,
      dateTime: firebase.firestore.Timestamp.now(),
      idUser: user.id
    });

    history.push("/compartir-rastreo/compartir/" + doc.id+"&1");
  }
  
  return (
    <Div100vh>
      <AppBar title="Compartir rastreo" />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} style={{marginTop: 10}}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel>Tiempo</InputLabel>
              <Select
                label="Tiempo"
                value={time}
                onChange={(event) => setTime(event.target.value)}
              >
                <MenuItem value={"15"}>15 Minutos</MenuItem>
                <MenuItem value={"30"}>30 Minutos</MenuItem>
                <MenuItem value={"60"}>1 Hora</MenuItem>
                <MenuItem value={"240"}>4 Horas</MenuItem>
                <MenuItem value={"480"}>8 Horas</MenuItem>
                <MenuItem value={"720"}>12 Horas</MenuItem>
                <MenuItem value={"1440"}>24 Horas</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField 
              size="small"
              variant="filled"
              fullWidth
              label="Buscar"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card style={{ maxHeight: "70vh", overflowY: "auto" }}>
            {
              cars.filter(c => 
                c.marca.toUpperCase().includes(search.toUpperCase())
                || c.modelo.toUpperCase().includes(search.toUpperCase())
                || c.nombre.toUpperCase().includes(search.toUpperCase())
              )
              .map((car, index) => (
                <ListItem key={index}>
                  <ListItemText primary={car.nombre} secondary={car.marca + " "  + car.modelo}/>
                  <ListItemIcon>
                    <Switch 
                      onChange={() => setCars(cars.map((c, i) => ({...c, selected: i === index ? !c.selected : c.selected})))}
                      checked={car.selected}
                      color="primary"
                    />
                  </ListItemIcon>
                </ListItem>
              ))
            }
            </Card>
          </Grid>
        </Grid>
        <Avatar 
          style={{
            backgroundColor: "#1388ca", 
            cursor: "pointer", 
            position: "absolute", 
            right: 0, 
            bottom: 0, 
            marginBottom: 10, 
            marginRight: 10
          }} 
          onClick={async () => await toShare()}
        >
          <Share />
        </Avatar>
      </Container>
      <FullScreenLoaderView open={loading} />
      <Alert
        open={Boolean(error)}
        onClose={() => setError("")}
        severity="error"
        message={error}
      />
    </Div100vh>
  );
};

export default ShareTracking;
