import React from "react";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";

const localStorageTokenKey = "token-chekcar";
const localStorageTokenKey2 = "token-chekcar2";

const localStorageUserIdAuth = "userIdChekcar";
const AppContext = React.createContext();

export function TokenProvider(props) {
  const [token, setToken] = React.useState(null);
  const [userAuth, setUserAuth] = React.useState(0);
  const [appLoading, setAppLoading] = React.useState(true);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [hours, setHours] = React.useState("2");
  const [points, setPoints] = React.useState([]);
  const [geofences, setGeofences] = React.useState([]);
  const [token2, setToken2] = React.useState(null);

  React.useEffect(() => {
    const jwt = localStorage.getItem(localStorageTokenKey);
    const ua = localStorage.getItem(localStorageUserIdAuth);
    const jwt2 = localStorage.getItem(localStorageTokenKey2);

    if (jwt) {
      setToken(jwt);
    }

    if (jwt2) {
      setToken2(jwt2);
    }

    if (ua) {
      setUserAuth(ua);
    }

    setAppLoading(false);
  }, []);

  React.useEffect(() => {
    if (token) {
      localStorage.setItem(localStorageTokenKey, token);
    } else if (!token && localStorage.getItem(localStorageTokenKey)) {
      localStorage.removeItem(localStorageTokenKey);
    }

    if (token2) {
      localStorage.setItem(localStorageTokenKey2, token2);
    } else if (!token2 && localStorage.getItem(localStorageTokenKey2)) {
      localStorage.removeItem(localStorageTokenKey2);
    }

    if (userAuth) {
      localStorage.setItem(localStorageUserIdAuth, userAuth);
    } else if (!userAuth && localStorage.getItem(localStorageUserIdAuth)) {
      localStorage.removeItem(localStorageUserIdAuth);
    }
  }, [token, token2, userAuth]);

  const memData = React.useMemo(() => {
    return {
      token,
      setToken,
      token2,
      setToken2,
      appLoading,
      userAuth,
      setUserAuth,
      showDrawer,
      setShowDrawer,
      hours,
      setHours,
      points,
      setPoints,
      geofences,
      setGeofences,
    };
  }, [
    token,
    setToken,
    token2,
    setToken2,
    appLoading,
    userAuth,
    setUserAuth,
    showDrawer,
    setShowDrawer,
    hours,
    setHours,
    points,
    setPoints,
    geofences,
    setGeofences,
  ]);

  if (appLoading) return <FullScreenLoader />;

  return <AppContext.Provider value={memData} {...props} />;
}

export function useToken() {
  const context = React.useContext(AppContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: app context not defined.";
  }
  return context;
}
