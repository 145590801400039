import React, { useState, useEffect } from "react";
import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import iconsTypeByEvent from "./IconsTypeByEvent";
import moment from "moment-timezone";
import Speed from "@material-ui/icons/Speed";

const BottomSheets = ({
  propHistory,
  toPositionRowTable,
  showListHistory,
  setShowListHistory,
}) => {
  const [search, setSearch] = useState("");
  const [history, setHistory] = useState([]);

  useEffect(() => {
    if (propHistory) {
      setHistory(
        propHistory.sort((a,b) => moment(a[0].info.timestamp).toDate() > moment(b[0].info.timestamp).toDate() ? 1 : -1).map((h, index) => {
          if (h[0].info.eventType === 21) {
            let _timeOn = null
            if(propHistory[(index + 1)]){
              _timeOn = propHistory[(index + 1)][0]?.timestamp;
            }
            const timeOff = moment(h[0].info.timestamp);
            const timeOn = _timeOn ? moment(_timeOn) : moment();

            let diff;

            if (timeOn.diff(timeOff, "seconds") < 60) {
              diff = timeOn.diff(timeOff, "seconds") + " Segundos";
            } else if (timeOn.diff(timeOff, "minutes") < 60) {
              diff = timeOn.diff(timeOff, "minutes") + " Minutos";
            } else if (timeOn.diff(timeOff, "hours") < 24) {
              diff = timeOn.diff(timeOff, "hours") + " Horas";
            } else {
              diff = timeOn.diff(timeOff, "days") + " Dias";
            }

            return { 
              ...h,
              fechaFormat: moment(h[0].info.timestamp).format("DD/MM/YYYY"),
              horaFormat: moment(h[0].info.timestamp).format("HH:mm a"),
              speedFormat: h[0].info.speed.toFixed(0) + " Km/h",
              diff: diff 
            };
          } else {
            return {
              ...h,
              fechaFormat: moment(h[0].info.timestamp).format("DD/MM/YYYY"),
              horaFormat: moment(h[0].info.timestamp).format("HH:mm a"),
              speedFormat: h[0].info.speed.toFixed(0) + " Km/h",
            };
          }
        })
      );
    }
  }, [propHistory]);

  const getEventIcon = (data) => {
    if(data && data[0].info.image){
      return (
        <ListItemAvatar>
          <Avatar alt={"img"} src={`https://gps.chekcar.mx${data[0].info.image}`} />
        </ListItemAvatar>
      )
    }else if(iconsTypeByEvent.find(d => d.id === data[0].info.eventType)?.icon) return (<ListItemIcon>{iconsTypeByEvent.find(d => d.id === data[0].info.eventType)?.icon}</ListItemIcon>)
    else return (<ListItemIcon>{iconsTypeByEvent.find(d => d.id === 0)?.icon}</ListItemIcon>)
  }

  const getEventDescription = (data) => {
    if(data && data[0].info.DescripcionEvento?.length && data[0].info.DescripcionEvento?.length > 3) return data[0].info.DescripcionEvento
    else if(iconsTypeByEvent.find(d => d.id === data[0].info.eventType)?.descripcion) return iconsTypeByEvent.find(d => d.id === data[0].info.eventType)?.descripcion
    else return iconsTypeByEvent.find(d => d.id === 0)?.descripcion
  }

  return (
    <div
      style={{
        zIndex: 999,
        position: "fixed",
        bottom: 0,
        width: "100%",
        height: showListHistory ? "30vh" : "25px",
      }}
    >
      <div style={{ height: "28px", backgroundColor: "#1388ca", padding: 2 }}>
        {showListHistory && (
          <TextField
            placeholder=" Buscar evento"
            size="small"
            variant="standard"
            style={{ marginLeft: 10 }}
            InputLabelProps={{
              style: { color: "black" },
            }}
            InputProps={{
              style: { color: "black", backgroundColor: "white" },
            }}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
        <div style={{ float: "right", color: "white" }}>
          {showListHistory ? (
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowListHistory(!showListHistory)}
            />
          ) : (
            <ExpandLessIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowListHistory(!showListHistory)}
            />
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          height: showListHistory ? "27vh" : "25px",
          backgroundColor: "white",
        }}
      >
        {
          showListHistory && (history
          // .filter((h) => {
          //   const dato = iconsTypeByEvent.find(d=> d.descripcion.toUpperCase().includes(search.toUpperCase())).id
          //   return (
          //     search.length > 0 
          //       ? h[0].info.eventType === dato 
          //       : history
          //   )
          // })
          .map((h, i) => (
            <div
              key={i}
              style={{
                cursor: "pointer",
                backgroundColor: h.selected ? "#e0e0e0" : "white",
              }}
              onClick={async() =>{
                await toPositionRowTable(
                  { lat: h[0].info.latitude, lng: h[0].info.longitude },
                  h[0].info.id) 
                }
              }
            >
              <ListItem> { getEventIcon(h) }
                <ListItemText
                  primary={getEventDescription(h)}
                  secondary={h?.fechaFormat}
                />
              </ListItem>
              <Grid
                container
                spacing={2}
                style={{ marginTop: -10, color: "gray" }}
              >
                <Grid item xs={2} sm={2}></Grid>
                <Grid item xs={5} sm={5}>
                  <Grid container>
                    <Grid item>
                      <LocationOnIcon style={{ color: "lightgreen" }} />
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontSize: 14, marginTop: 4 }}>
                        { h?.horaFormat }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5} sm={5}>
                  <Grid container spacin={1}>
                    <Grid item>
                      <Speed style={{ color: "#1388ca" }} />
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontSize: 14, marginTop: 4 }}>
                        { h?.speedFormat }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {h[0].info.eventType === 21 && (
                <Grid container spacing={2} style={{ color: "gray" }}>
                  <Grid item xs={2} sm={2}></Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <LocationOnIcon style={{ color: "lightcoral" }} />
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: 14, marginTop: 4 }}>
                          {h?.diff}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <hr style={{ margin: 0, padding: 0 }} />
            </div>
          )))
        }
      </div>
    </div>
  );
};

export default BottomSheets;
