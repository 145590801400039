import React, { useEffect, useState } from "react";
import Container from "../../components/Container/Container";
import AppBar from "../../components/AppBar/AppBar";
import Div100vh from "react-div-100vh";
import { ListItem, ListItemIcon, ListItemText, Card } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import ShortTextIcon from "@material-ui/icons/ShortText";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import EventIcon from "@material-ui/icons/Event";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import PersonIcon from "@material-ui/icons/Person";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import SpeedIcon from "@material-ui/icons/Speed";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import StoreIcon from "@material-ui/icons/Store";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import { MenuBook, Phone } from "@material-ui/icons";

const DetailUnitView = ({ location }) => {
  const [car, setCar] = useState(null);

  useEffect(() => {
    if (location?.state.car) {
      setCar(location.state.car);
    }
  }, [location]);

  if (!car) return null;

  return (
    <Div100vh>
      <AppBar title="Detalle de unidad" />
      <Container>
        <div style={{ height: "94vh", overflowY: "auto" }}>
          <Card>
            <ListItem>
              <ListItemIcon>
                <DescriptionIcon style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText primary={car.nombre} secondary="Nombre" />
            </ListItem>
          </Card>
          <br />
          <Card>
            <ListItem>
              <ListItemIcon>
                <ViewComfyIcon style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText primary={car.marca} secondary="Marca" />
            </ListItem>
          </Card>
          <br />
          <Card>
            <ListItem>
              <ListItemIcon>
                <AirportShuttleIcon style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText primary={car.modelo} secondary="Modelo" />
            </ListItem>
          </Card>
          <br />
          <Card>
            <ListItem>
              <ListItemIcon>
                <EventIcon style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText primary={car.ano} secondary="Año" />
            </ListItem>
          </Card>
          <br />
          <Card>
            <ListItem>
              <ListItemIcon>
                <ShortTextIcon style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText primary={car.serie} secondary="Numero de serie" />
            </ListItem>
          </Card>
          <br />
          <Card>
            <ListItem>
              <ListItemIcon>
                <CreditCardIcon style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText primary={car.placa} secondary="Placas" />
            </ListItem>
          </Card>

          <br />
          <Card>
            <ListItem>
              <ListItemIcon>
                <ColorLensIcon style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  car.color.includes("#") ? (
                    <div
                      style={{
                        backgroundColor: car.color,
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                      }}
                    ></div>
                  ) : (
                    car.color
                  )
                }
                secondary="Color"
              />
            </ListItem>
          </Card>
          <br />
          <Card>
            <ListItem>
              <ListItemIcon>
                <StoreIcon style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText
                primary={car?.seguro_nombre || <i>{"Sin nombre"}</i>}
                secondary="Nombre del Seguro"
              />
            </ListItem>
          </Card>

          <br />
          <Card>
            <ListItem>
              <ListItemIcon>
                <MenuBook style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText
                primary={car?.seguro_poliza || <i>{"Sin póliza de seguro"}</i>}
                secondary="Póliza de Seguro"
              />
            </ListItem>
          </Card>

          <br />
          <Card>
            <ListItem>
              <ListItemIcon>
                <Phone style={{ color: "#1388ca" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  car?.seguro_telefono || <i>{"Sin teléfono de seguro"}</i>
                }
                secondary="Teléfono del Seguro"
              />
            </ListItem>
          </Card>
          <br />
        </div>
      </Container>
    </Div100vh>
  );
};

export default DetailUnitView;
