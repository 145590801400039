import Autobus from '../../cars/green/Autobus.png';
import Automovil from '../../cars/green/Automovil.png';
import Cajadecarga from '../../cars/green/Caja de carga.png';
import Camiondecemento from '../../cars/green/Camion de cemento.png';
import Camiondeliquido from '../../cars/green/Camion de liquido.png';
import Camiongrande from '../../cars/green/Camion grande.png';
import Camionsincarga from '../../cars/green/Camion sin carga.png';
import Camion from '../../cars/green/Camion.png';
import Camioneta from '../../cars/green/Camioneta.png';
import Default from '../../cars/green/default.png';
import Furgoneta from '../../cars/green/Furgoneta.png';
import Furgonetamediana  from '../../cars/green/Furgoneta mediana.png';
import Generador  from '../../cars/green/Generador.png';
import Grua from '../../cars/green/Grua.png';
import Hatchbackmediano  from '../../cars/green/Hatchback mediano.png';
import hatchback  from '../../cars/green/hatchback.png';
import Maquinadeconstruccion from '../../cars/green/Maquina de construccion.png';
import MiniVan from '../../cars/green/MiniVan.png';
import Motoclasica from '../../cars/green/Moto clasica.png';
import Motocomercial from '../../cars/green/Moto comercial.png';
import Motodecampo from '../../cars/green/Moto de campo.png';
import Motodeportiva from '../../cars/green/Moto deportiva.png';
import Motoneta from '../../cars/green/Motoneta.png';
import PickUpdetrabajo from '../../cars/green/PickUp de trabajo.png';
import PickUpindustrial from '../../cars/green/PickUp industrial.png';
import PickUpmediana from '../../cars/green/PickUp mediana.png';
import pickup from '../../cars/green/pickup.png';
import PickUp from '../../cars/red/pickup.png';
import Plataforma from '../../cars/green/Plataforma.png';
import Sedanmediano from '../../cars/green/Sedan mediano.png';
import Sedan from '../../cars/green/Sedan.png';
import SUV from '../../cars/green/SUV.png';
import Trailer from '../../cars/green/Trailer.png';
import CarOn from '../../cars/car-on.png';
 
const cars = {
  "Autobus": Autobus,
  "Automovil": Automovil, 
  "Cajadecarga": Cajadecarga, 
  "Camiondecemento": Camiondecemento, 
  "Camiondeliquido": Camiondeliquido,
  "Camiongrande": Camiongrande,
  "Camionsincarga": Camionsincarga, 
  "Camion": Camion,
  "Camioneta": Camioneta,  
  "default": Default,  
  "Furgoneta": Furgoneta, 
  "Furgonetamediana": Furgonetamediana, 
  "Generador": Generador,
  "Grua": Grua, 
  "Hatchbackmediano": Hatchbackmediano, 
  "hatchback": hatchback,
  "Maquinadeconstruccion": Maquinadeconstruccion,
  "MiniVan": MiniVan,
  "Motoclasica": Motoclasica,
  "Motocomercial": Motocomercial, 
  "Motodecampo": Motodecampo,
  "Motodeportiva": Motodeportiva, 
  "Motoneta": Motoneta,
  "PickUpdetrabajo": PickUpdetrabajo, 
  "PickUpindustrial": PickUpindustrial, 
  "PickUpmediana": PickUpmediana,
  "pickup": pickup,  
  "Plataforma": Plataforma,
  "Sedanmediano": Sedanmediano,
  "Sedan": Sedan,
  "SUV": SUV,
  "Trailer": Trailer,  
  "PickUp": PickUp,
  "CarOn": CarOn
};


const GreenMarker = (image, info) => {
  const lengthName = info?.nombre?.length;
  const marginLeft = -(lengthName * 3);
  return (
    `<div style="margin-top: 0px; margin-left: ${marginLeft}px; ">
      <center><img src="${cars[image]}" style="height:15px" /></center>
      <div style="background-color: #4caf50; color: white; border-radius: 25px; padding:2px 4px"> 
        <center><label style="font-size: 10px; font-family: Arial;"> ${info.nombre} </label></center>
      </div>
    </div>`
  )
}

export default GreenMarker;
