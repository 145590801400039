import React, { useEffect, useState } from 'react';
import { ListItem, ListItemIcon, ListItemText, Grid } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LanguageIcon from '@material-ui/icons/Language';
import Services from '../../services';
import ColorLensIcon from '@material-ui/icons/ColorLens';

const BottomSheets = ({point}) => {
  const [address, setAddress] = useState("");

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      if(point && mounted) {
        const reponseGeoCoder = await Services.geoCoder(point.lat, point.lon);
        const data = reponseGeoCoder.data;
        const _address = data["Response"]["View"][0]["Result"][0]["Location"]["Address"];

        setAddress(_address["Label"])
      }
    }

    getData();

    return () => { mounted = false; }
  }, [point])

  if(!point) return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "white",
        height: "22vh",
        zIndex: 999,
        overflowY: "auto",
        overflowX: "hidden"
      }}
    >
      <ListItem style={{marginTop: -10, marginBottom: -20}}>
        <ListItemIcon><RoomIcon style={{color: "#1388ca"}}/></ListItemIcon>
        <ListItemText primary={point.nombre} secondary="Nombre" />
      {/*   <IconButton onClick={() => onClose()}><CloseIcon style={{color: "#1388ca"}}/></IconButton> */}
      </ListItem>
      <hr style={{width: "98%"}} />
      <Grid container style={{marginTop: -18}}>
        <Grid item xs={6} md={6}>
          <ListItem>
            <PhoneIcon style={{color: "#1388ca", marginRight: 10}} />
            <ListItemText primary={point.phone || "Sin teléfono"} secondary="Teléfono" />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6}>
          <ListItem>
            <EmailIcon style={{color: "#1388ca", marginRight: 10}} />
            <ListItemText primary={point.correosNotificacion || "Sin correo"} secondary="Teléfono" />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6} style={{marginTop: -15}}>
          <ListItem>
           <AccessTimeIcon style={{color: "#1388ca", marginRight: 10}} />
            <ListItemText primary={point.horaInicio || "Sin hora inicio"} secondary="hora inicio" />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6} style={{marginTop: -15}}>
          <ListItem>
           <AccessTimeIcon style={{color: "#1388ca", marginRight: 10}} />
            <ListItemText primary={point.horaFin || "Sin hora fin"} secondary="hora fin" />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6} style={{marginTop: -15}}>
          <ListItem>
            <ColorLensIcon style={{color: "#1388ca", marginRight: 10}} />
            <ListItemText 
              primary={
                <div style={{backgroundColor: point.color, height: 25, width: 25, borderRadius: 20 }}></div>
              } 
              secondary="Color"
            />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6} style={{marginTop: -15}}>
          <ListItem>
            <LanguageIcon style={{color: "#1388ca", marginRight: 10}} />
            <ListItemText primary={point.web || "Sin dirección web"} secondary="Dirección web" />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={12} style={{marginTop: -15}}>
          <ListItem>
            <RoomIcon style={{color: "#1388ca", marginRight: 10}} />
            <ListItemText primary={address} secondary="Ubicación" />
          </ListItem>
        </Grid>
      </Grid>
    </div>
  )
}

export default BottomSheets;