import Services from "../../services";
import moment from "moment";

const getUrlStreetMap = (location) =>
  "https://maps.google.com/maps?q=&layer=c&cbll=" +
  location.lat +
  "," +
  location.lng;

const getUrlMaps = (location) =>
  "https://maps.google.com/maps?q=" + location.lat + "," + location.lng;

const getBubble = async (evt, getEndLocation, getEndInfo) => {
  const location = getEndLocation();
  const info = getEndInfo();
  const reponseGeoCoder = await Services.geoCoder(location.lat, location.lng);
  const data = reponseGeoCoder.data;
  const address =
    data["Response"]["View"][0]["Result"][0]["Location"]["Address"];
  
  const lastDate = moment(info.fechautc, "YYYY-MM-DD HH:mm:ss");
  const today = moment();
  const diff = today.diff(lastDate, "days");

  const normalContent = () => (
    "<div style='width: 150px'>" +
      "<label> <b>Evento:</b> " +
      info.NombreEvento +
      "</label>" +
      "<br />" +
      "<label> <b>Fecha:</b> " +
      info.fechautc.split(" ")[0] +
      "</label>" +
      "<br />" +
      "<label> <b>Hora:</b> " +
      info.fechautc.split(" ")[1] +
      "</label>" +
      "<br />" +
      "<label> <b>Dirección:</b> " +
      address["Label"] +
      ". </label>" +
      "<br />" +
      "<label> <b>Velocidad:</b> " +
      Math.trunc(info.speed) +
      " Km/h " +
      "</label>" +
      "<br />" +
      "<label> <b>Voltaje:</b> " +
      info.powerVoltage +
      " V " +
      "</label>" +
      "<br />" +
      "<li><a href=" +
      getUrlStreetMap(location) +
      " target='_blank' style='color:#116EBF'> Vista en calle </a></li> " +
      "<li><a href=" +
      getUrlMaps(location) +
      " target='_blank' style='color:#116EBF'> Google Maps </a></li>" +
      "</div>"
  )

  const noSignalContent = () => (
    "<div style='width: 150px'>" +
      "<label> <b>SIN SEÑAL: <br />" +
      "El dispositivo no ha enviado señal desde hace " + diff + " días.</b>" +
      "</label>" +
      "<br /><br />" +
      "<label> <b>Evento:</b> " +
      info.NombreEvento +
      "</label>" +
      "<br />" +
      "<label> <b>Fecha:</b> " +
      info.fechautc.split(" ")[0] +
      "</label>" +
      "<br />" +
      "<label> <b>Hora:</b> " +
      info.fechautc.split(" ")[1] +
      "</label>" +
      "<br />" +
      "<label> <b>Dirección:</b> " +
      address["Label"] +
      ". </label>" +
      "<br />" +
      "<label> <b>Velocidad:</b> " +
      Math.trunc(info.speed) +
      "</label>" +
      "<br />" +
      "<li><a href=" +
      getUrlStreetMap(location) +
      " target='_blank' style='color:#116EBF'> Vista en calle </a></li> " +
      "<li><a href=" +
      getUrlMaps(location) +
      " target='_blank' style='color:#116EBF'> Google Maps </a></li>" +
      "</div>"
  )

  return new H.ui.InfoBubble(evt.target.getGeometry(), {
    content: diff >= 1 ? noSignalContent()  : normalContent(),
  });
};

export default getBubble;
