const events = [
  {
    id: 2,
    name: "Arrastre de Vehículo",
    code: "EMG - 2",
    img: "/img/iconsEvents/XhfBQRkz3-W9N_rHyf2kbSESWuaSbHuw.png",
  },
  {
    id: 6,
    name: "Fin de exceso de velocidad",
    code: "ALT - 2",
    img: "/img/iconsEvents/fCtuMAA6L-Wtp9C0Vi3Nyqo3HynpY9wN.png",
  },
  {
    id: 5,
    name: "Inicio de exceso de velocidad",
    code: "ALT - 1",
    img: "/img/iconsEvents/V43Z9QNNaxUlGA7IwWvyqHv1rPV6aFOC.png",
  },
  {
    id: 15,
    name: "Batería de vehículo baja",
    code: "ALT - 14",
    img: "/img/iconsEvents/qwi5hYFoVsf4e5554kicyEDBdPpDh4WS.png",
  },
  {
    id: 22,
    name: "Batería Externa Conectada",
    code: "ALT - 40",
    img: "/img/iconsEvents/jHKol5Hb8bSck7UMR8Ne-PcurSctbCCG.png",
  },
  {
    id: 31,
    name: "Inicia Ralentí",
    code: "ALT - 68",
    img: "/img/iconsEvents/aCLI-GlrWaTzL2N-WtU0b0h2Plor2ZpK.png",
  },
  {
    id: 32,
    name: "Fin de Ralentí",
    code: "ALT - 69",
    img: "/img/iconsEvents/as56Hw0qwODJTnHD3ZiMUVIDb0yydeOR.png",
  },
  {
    id: 20,
    name: "Motor Encendido",
    code: "ALT - 33",
    img: "/img/iconsEvents/qJf4ULt2c3KKRXC4sTcCFjkJHuM-LkfV.png",
  },
  {
    id: 24,
    name: "Batería Interna Conectada",
    code: "ALT - 44",
    img: "/img/iconsEvents/-AGGsAOxYnqmdWbQ2Z8zeEIS0HKxNLrG.png",
  },
  {
    id: 66,
    name: "Batería externa baja",
    code: "",
    img: "",
  },
  {
    id: 65,
    name: "Batería interna baja",
    code: "",
    img: "",
  },
  {
    id: 30,
    name: "Interferencia detectado",
    code: "ALT - 50",
    img: "/img/iconsEvents/ptyBXVZ_f7yB2MaLObg5bKXn05KWUV-4.png",
  },
  {
    id: 72,
    name: "Sin señal GPRS-GSM",
    code: "",
    img: "/img/iconsEvents/DnZGJuZJTrZZY_uGjUZGs6Sr5PXXIN4j.png",
  },
  {
    id: 9,
    name: "Salida de geocerca",
    code: "ALT - 5",
    img: "/img/iconsEvents/vBiABqmqWRSlfRCxSBpvH6XIzUMgQzfH.png",
  },
  {
    id: 28,
    name: "Vuelta repentina",
    code: "ALT - 48",
    img: "/img/iconsEvents/iOTKyyBEYDMScy_o3W0Tdbgopd8jweZE.png",
  },
  {
    id: 26,
    name: "Aceleración súbita",
    code: "ALT - 46",
    img: "/img/iconsEvents/uawjnBb65xmB0ueqtS9vamdWGMRnpU_b.png",
  },
  {
    id: 16,
    name: "Choque",
    code: "ALT - 15",
    img: "/img/iconsEvents/jAAQG6yB_C22DveljoyLro0A1xem01_3.png",
  },
  {
    id: 17,
    name: "Colisión",
    code: "ALT - 16",
    img: "/img/iconsEvents/Agl77nUAE8CLL5t4o4e9twrhRodSQgI5.png",
  },
  {
    id: 27,
    name: "Freno Fuerte",
    code: "ALT - 47",
    img: "/img/iconsEvents/L_F3klwluoCY3i0mxxRXEPSKj1tK9oMR.png",
  },
  {
    id: 25,
    name: "Batería Interna Desconectada",
    code: "ALT - 45",
    img: "/img/iconsEvents/aBnK3Z3Fec3UEgc2Jtoo6ifurCACmfuI.png",
  },
  {
    id: 21,
    name: "Motor Apagado",
    code: "ALT - 34",
    img: "/img/iconsEvents/7S7MxpGiUaPMgmCklaPdKOF1Jf74hv68.png",
  },
  {
    id: 1,
    name: "Botón de pánico",
    code: "EMG - 1",
    img: "/img/iconsEvents/RmVqaervuJy4k6wOgbfvki5wPqCnNLgs.png",
  },
  {
    id: 23,
    name: "Batería Externa Desconectada",
    code: "ALT - 41",
    img: "/img/iconsEvents/cRn18S9wf9gspHAdfS3QfPvvd0z4T15A.png",
  },
  {
    id: 19,
    name: "Entrada a Ruta",
    code: "ALT - 19",
    img: "/img/iconsEvents/lsIpx6WVNFgvqAnfzJhvqB0mn8j2wRsc.png",
  },
  {
    id: 71,
    name: "Ruptura de convoy",
    code: "",
    img: "/img/iconsEvents/XhfBQRkz3-W9N_rHyf2kbSESWuaSbHuw.png",
  },
  {
    id: 10,
    name: "Entrada a geocerca",
    code: "ALT - 6",
    img: "/img/iconsEvents/jdPtVrg9HjdTEybZTREp2ZVAK9rBN0J1.png",
  },
  {
    id: 18,
    name: "Salida de Ruta",
    code: "ALT - 18",
    img: "/img/iconsEvents/bLYKgcYxjwcPLWxKl8x92Kekz6ZRUhqN.png",
  },
];

const eventos = events.sort((a, b) => {
  const textA = a.name.toUpperCase();
  const textB = b.name.toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
});

export { eventos };
