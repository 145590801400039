import React, { useEffect, useState } from 'react'
import CommuteIcon from '@material-ui/icons/Commute';
import { Card, TextField, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Div100vh from 'react-div-100vh'
import AppBar from '../../components/AppBar/AppBar'
import Container from '../../components/Container/Container'
import FullScreenLoaderView from '../../components/FullScreenLoaderView/FullScreenLoaderView';
import Services from '../../services';

const miStorage = window.localStorage;

const GroupsView = () => {
  const [search, setSearch] = useState("");
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      if(mounted)  {
        try { 
          const { idCuenta, id } = JSON.parse(miStorage.getItem("usuarioChekcar"));

          const response = await Services.get2("grupo?idCuenta=" + idCuenta + "&idUsuario=" + id);

          if(response?.datos) {
            setGroups(response.datos);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    getData();

    return () => { mounted = false }
  }, []);

  return (
    <Div100vh>
      <AppBar title="Compartir rastreo" />
      <Container>
        <TextField 
          size="small"
          variant="filled"
          fullWidth
          label="Buscar"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Card style={{marginTop: 20, maxHeight: "80vh", overflowY: "auto"}}>
        {
          groups
          .filter(c => 
            c.model.nombre.toUpperCase().includes(search.toUpperCase())
          )
          .map((g, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CommuteIcon style={{color: "#1388ca"}} />
              </ListItemIcon>
              <ListItemText primary={g.model.nombre} secondary={g.descripcion || "Sin descripción"}/>
            </ListItem>
          ))
        } 
        </Card>
      </Container>
     
      <FullScreenLoaderView open={loading} />
    </Div100vh>
  )
}

export default GroupsView;
