import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Tooltip } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { useToken } from "../hooks/useToken";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  doubleNested: {
    paddingLeft: theme.spacing(6),
  },
}));

const CollapsableList = ({
  icon,
  title,
  links = [],
  allowAll = false,
  ...rest
}) => {
  const [collapse, setCollapse] = React.useState(false);
  const toggleCollapse = () => setCollapse((c) => !c);
  const classes = useStyles();
  const { showDrawer, setShowDrawer} = useToken();

  return (
    <>
      <Tooltip placement="right" title={title} arrow>
        <ListItem button onClick={toggleCollapse} {...rest}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText style={{ whiteSpace: "pre-wrap" }} primary={title} />
          {links?.length > 0 && (collapse ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
      </Tooltip>

      <Collapse in={collapse} timeout="auto" >
        <List component="div" disablePadding>
          {links.map((link, i) => <NavLink
                  to={link.to}
                  key={i}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Tooltip placement="right" title={link.title} arrow>
                    <ListItem
                      onClick={() => setShowDrawer(!showDrawer)}
                      button
                      className={classes.nested
                      }
                    >
                        <ListItemIcon>{link.icon}</ListItemIcon>
                      <ListItemText
                        primary={link.title}
                        style={{ whiteSpace: "pre-wrap" }}
                      />
                    </ListItem>
                  </Tooltip>
                </NavLink>
          )}
          <Divider />
        </List>
      </Collapse>
    </>
  );
};

export default CollapsableList;