import React, { useEffect, useState } from "react";

import Div100vh from "react-div-100vh";
import {
  ListItemIcon,
  TextField,
  Card,
  ListItem,
  ListItemText,
  IconButton,
  Switch,
  Divider,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import GroupWorkIcon from "@material-ui/icons/GroupWork";

import AppBar from "../../components/AppBar/AppBar";
import Container from "../../components/Container/Container";
import FullScreenLoaderView from "../../components/FullScreenLoaderView/FullScreenLoaderView";
import Services from "../../services";
import { eventos } from "../../constants";
import iconsType from "../Historical/IconsType";
import iconsEvent from "../Historical/IconsEvent";
import services from "../../services";
import firebase from "firebase";
const Eventos = ({ location }) => {
  const [car, setCar] = useState(null);
  const [carId, setCarId] = useState(0);
  const [loading, setLoading] = React.useState(true);
  const [switches, setSwitches] = React.useState({});
  const [events, setEvents] = React.useState([]);


  React.useEffect(() => {
    if (location?.state.car) {
      setCar(location.state.car);
      setCarId(location.state.car.id);
    }

  }, [location]);

  React.useEffect(() => {

    const load = async () => {

      const userString = localStorage.getItem("usuarioChekcar");
      const user = JSON.parse(userString);
      console.log(user.id);
      if (user) {
        const res = await services.getDocumentById(
          "notificaciones",
          user.id.toString()
        );

        if (res.exists) {
          const data = res.data();
          if (data[carId]){
            setEvents(data[carId]);
           }
            else{
            console.log("No DAta");

              await services.updateDocument("notificaciones", user.id.toString(), {
                [carId]: []
              });
            }
          
        }
      }
      setLoading(false);
    };
    load();
  }, [carId]);

  if (!car) return null;
  const onCheckBoxChanged = async (key) => {
    const userString = localStorage.getItem("usuarioChekcar");
    const user = JSON.parse(userString);

    const document = await Services.getDocumentById("notificaciones", user.id.toString()); 

    if( !document.exists ) {
      await Services.addDocById("notificaciones", user.id.toString(), { carId: [] } );
    }


    if (events && events.includes(key) === true) {
      const res = await services.updateDocument("notificaciones", user.id.toString(), {
        [carId]: firebase.firestore.FieldValue.arrayRemove(key)
      });

      let new_array = events.filter(e => e !== key)
      setEvents(new_array);
    }

    if (events && events.includes(key) != true){
      await services.updateDocument("notificaciones", user.id.toString(), {
        [carId]: firebase.firestore.FieldValue.arrayUnion(key)
      });  
  
      try {
        setEvents(events.concat(key));
      }
      catch(err) {
        setEvents([key]);
      } 
    }


  };
  return (
    <Div100vh>
      <AppBar title={"Notificaciónes de "+ location.state.car.nombre } />
      <Container>


        <Card  style={{ maxHeight: "80vh", marginTop: 20, overflowY: "auto" }}>
          {eventos.map((event, i) => (
            <ListItem key={i + 1}>
              <ListItemIcon>
                {iconsEvent[event.name] ? (
                  <img
                    src={iconsEvent[event.name]}
                    height={25}
                    alt="event-icon"
                  />
                ) : (
                  <GroupWorkIcon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={event.name || "-"}
                secondary={event.code || "Sin código"}
              />
              <ListItemIcon>
                <Switch
                  color="primary"
                  checked={events && events.includes(event.id) === true}
                  onChange={(e) => onCheckBoxChanged(event.id)}
                />
                {/* <IconButton style={{ color: "#1388ca" }}>
                  <ArrowForwardIosIcon />
                </IconButton> */}
              </ListItemIcon>
            </ListItem>
          ))}
        </Card>
      </Container>
      <FullScreenLoaderView open={loading} />
    </Div100vh>
  );
};

export default Eventos;
