import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCZFvGRvcu6pTwKDcsDvNcMy1g9Nxv6B6s",
  authDomain: "chek-car.firebaseapp.com",
  projectId: "chek-car",
  storageBucket: "chek-car.appspot.com",
  messagingSenderId: "490435261479",
  appId: "1:490435261479:web:d3464646b52c5f5b9a4a02",
  measurementId: "G-K0KCEXL4Q9"
};

firebase.initializeApp(firebaseConfig);

export default firebase;