import React, { useRef, useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AppBar from "../../components/AppBar/AppBar";
import Services from "../../services";
import RedMarker from "./RedMarker";
import GreenMarker from "./GreenMarker";
import FullScreenLoaderView from "../../components/FullScreenLoaderView/FullScreenLoaderView";
import BottomSheets from "./BottomSheets";
import getBubble from "./Bubble";
import { Card, Grid, Typography } from "@material-ui/core";
import { useToken } from "../../hooks/useToken";
import moment from "moment";

const platform = new H.service.Platform({
  apikey: "kUsKaAJJxXkSBces1hmFjNrIPBKRjZyXezk66tCyhXk",
});
const now = new Date();
const utc = now.getTimezoneOffset().toString();
const hoursUtc = parseFloat(utc) / 60;

const getSvg = (color, nombre) => `<div  style="margin-top: -20px; margin-left-30px">
<center><svg width="20" height="40" viewBox="1 -10 60 78" xmlns="http://www.w3.org/2000/svg" stroke="black" fill="${color}"><path d="M53.1,48.1c3.9-5.1,6.3-11.3,6.3-18.2C59.4,13.7,46.2,0.5,30,0.5C13.8,0.5,0.6,13.7,0.6,29.9 c0,6.9,2.5,13.1,6.3,18.2C12.8,55.8,30,77.5,30,77.5S47.2,55.8,53.1,48.1z"></path></svg></center>
<div style="background-color: ${color}; color: white; border-radius: 25px; padding:1px"  > 
  <center><label style="font-size: 10px; font-family: Arial;"> ${nombre} </label></center>
</div>
</div>`;


const getNewRoutes = async (ids, first) => {
  const response = await Services.post2("prueba/devices-pos-data", {
    id: ids,
    utc: hoursUtc,
  });

  return response.data.map((d) =>
    first
      ? [
          {
            info: d,
            location: {
              lat: parseFloat(d.latitude),
              lng: parseFloat(d.longitude),
            },
          },
        ]
      : {
          info: d,
          location: {
            lat: parseFloat(d.latitude),
            lng: parseFloat(d.longitude),
          },
        }
  );
};

function sleep() {
  return new Promise((resolve) => setTimeout(resolve, 1000));
}

const MapContinuosTracking = ({ location }) => {
  const { points, geofences } = useToken();
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [ui, setUi] = useState(null);
  const [locations, setLocations] = useState([]);
  const [cars, setCars] = useState([]);
  const [car, setCar] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(1000);
  const [seconds, setSeconds] = useState(0);
  const [point, setPoint] = useState(null);
  const [markersPoint, setMarkerPoint] = useState([]);
  const [zoom, setZoom] = useState(null)
  const [settingCars, setSettingCars] = useState([])
  const [filled, setFilled] = useState(false)

  const drawTypeGeofence = (dataGeofence, map, center = false) => {
    const { lat, lon, type, color, radio, vertices, top, left, bottom, right } =
      dataGeofence;
    let box;

    if (type === 1) {
      box = new H.map.Circle({ lat: lat, lng: lon }, radio, {
        style: {
          strokeColor: color,
          lineWidth: 2,
          fillColor: color,
        },
      });
    } else if (type === 2) {
      const lineString = new H.geo.LineString();

      vertices.forEach((v, i) => {
        if (i % 2 == 0) {
          lineString.pushPoint({ lat: v, lng: vertices[i + 1] });
        }
      });

      box = new H.map.Polygon(lineString, {
        style: {
          fillColor: color,
          strokeColor: color,
          lineWidth: 2,
        },
      });
    } else {
      const rec = new H.geo.Rect(top, left, bottom, right);

      box = new H.map.Rect(rec, {
        style: {
          fillColor: color,
          strokeColor: color,
          lineWidth: 2,
        },
      });
    }

    map.addObject(box);
    if (center) {
      map.getViewModel().setLookAtData({ bounds: box.getBoundingBox() });
    }
  };

  const drawPonins = async (points = []) => {
    try {
      const pointsGroup = points.map((p) => {
        const bearsIcon = new H.map.DomIcon(getSvg(p.color, p.nombre));
        const location = { lat: p.lat, lng: p.lon };
        const marker = new H.map.DomMarker(location, { icon: bearsIcon });

        marker.addEventListener("tap", () => {
          map.setZoom(18);
          map.setCenter(location);
          setPoint(p);
        });

        return marker;
      });

      const group = new H.map.Group();
      group.addObjects(pointsGroup);
      if (geofences && !Array.isArray(geofences)) {
        drawTypeGeofence(geofences, map, false);
      }

      map.addObject(group);

      setMarkerPoint(pointsGroup);
    } catch (error) {
      console.log(error);
    }
  };

  const noSignalMarker = (url, info) => {
    return (
      `<div style="margin-top: -20px; margin-left-30px">
        <center><img src="${url}" style="height:100px" /></center>
        <div style="background-color: #e52828; color: white; border-radius: 25px; padding: 1px"  > 
          <center><label style="font-size: 10px; font-family: Arial;"> ${info?.nombre} </label></center>
        </div>
      </div>`
    )
  }

  const onResult = (result, info, totalVehiculos) => {
    if (result.routes.length) {
      result.routes[0].sections.forEach((section, index) => {
        const linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
        const routeLine = new H.map.Polyline(linestring, {
          style: {
            lineWidth: 10,
            strokeColor: "rgba(0, 128, 255, 0.7)",
            lineTailCap: "arrow-tail",
            lineHeadCap: "arrow-head",
          },
        });
        const routeArrows = new H.map.Polyline(linestring, {
          style: {
            lineWidth: 10,
            fillColor: "white",
            strokeColor: "rgba(255, 255, 255, 1)",
            lineDash: [0, 2],
            lineTailCap: "arrow-tail",
            lineHeadCap: "arrow-head",
          },
        });

        let domIconEnd, image;
        // console.log("endinfo: ", info.endInfo);
        let speed = parseFloat(info.endInfo.speed);
        // if (info.endInfo.NombreEvento === "-") {
        // const carOnEnd = info.endInfo.ioStatus[9] === "f" ? false : true;
        const imageName = info.startInfo.imageIcon.split("/")[3];
        image = imageName
          .substring(0, imageName.length - 4)
          .replaceAll(" ", "")
          .replace("-", "");
        // domIconEnd = carOnEnd
        //   ? GreenMarker(image, info.endInfo)
        //   : RedMarker(image, info.endInfo);
        // }
        if (speed >= 5) {
          domIconEnd = GreenMarker("Automovil", info.startInfo);
        } else {
          domIconEnd = RedMarker("Automovil", info.endInfo);
        }
        // diFf de días con la fecha actual
        // Nota: Se cambió el formato para poder controlarlo a partir 24 horas
        const _dataLastCar = info?.endInfo;
        const lastDate = moment(_dataLastCar?.fechautc, "YYYY-MM-DD HH:mm:ss");
        const today = moment();
        const diff = today.diff(lastDate, "days");
        if(diff >= 1){
          domIconEnd = noSignalMarker("https://gps.chekcar.mx/img/imagesDevices/green/sinsenal.png", _dataLastCar)
        }
        
        // else if (info.endInfo.NombreEvento === "Motor Apagado") {
        //   domIconEnd = RedMarker("CarOff", info.startInfo);
        // } else if (info.endInfo.NombreEvento === "Motor Encendido") {
        //   domIconEnd = GreenMarker("CarOn", info.startInfo);
        // } else {
        //   domIconEnd = RedMarker(image, info.endInfo);
        // }
        // debugger;
        const endLocation = section.arrival.place.location;
        const endMarker = new H.map.DomMarker(endLocation, {
          icon: new H.map.DomIcon(domIconEnd),
        });

        const getEndLocation = () => endLocation;
        const getEndInfo = () => info.endInfo;
        let isOpen = open;
        const loc = getEndLocation()
        const inf = getEndInfo()        
      

        // map.addEventListener('mapviewchangeend', function () {
        //     setZoom(map.getZoom())
        // });

        setSettingCars(cars => 
          cars.map((car => 
            car?.id === inf?.id
              ?
                {
                  ...car,
                  latitude: loc?.lat,
                  longitude: loc?.lng,
                  loc: loc,
                  inf: inf,
                  add: false
                }
              : car
          ))
        )
        
        endMarker.addEventListener("tap", async (evt) => {

          const bubble = await getBubble(evt, getEndLocation, getEndInfo);

          bubble.addEventListener("statechange", (evt) => {
            if (evt.target.getState() === H.ui.InfoBubble.State.CLOSED) {
            }
          });

          if (ui.getBubbles().length) {
            ui.getBubbles().forEach((bub) => ui.removeBubble(bub));
          }

          ui.addBubble(bubble);
          // map.setCenter(getEndLocation());
          // map.setZoom(18);

          setCar((_) => info.endInfo);
          isOpen = !isOpen;
          if (!isOpen) {
            if (ui.getBubbles().length) {
              ui.getBubbles().forEach((bub) => ui.removeBubble(bub));
            }
          }
          setOpen(isOpen);
        });

        // map.setCenter(getEndLocation());

        endMarker.dispatchEvent("tap");

        let objs = [];
        if (result.routes[0].sections.length === 1) {
          objs = [routeLine, routeArrows, endMarker];
        } else {
          if (index === 0) {
            objs = [routeLine, routeArrows];
          } else if (index === result.routes[0].sections.length - 1) {
            objs = [routeLine, routeArrows, endMarker];
          } else {
            objs = [routeLine, routeArrows];
          }
        }

        const group = new H.map.Group()
        group.addObjects(objs);
        map.addObject(group);

        if(totalVehiculos === 1) {
          map.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
          });
          map.setZoom(16)
        }
        // map.addObjects(objs);
      });
    }

    setLoading(false);
    setTime(60000);

  };

  const searchCar = async (_cars) => {
    const ids = _cars.map((c) => c.id).join();
    const _locations = await getNewRoutes(ids, true);

    //hacer los un array de markers para los bounds grupo
    const geoPointsObjs = _locations.map(
      (d) => new H.map.Marker(d[0].location)
    );

    //centrar mapa y zoom de mapa
    const group = new H.map.Group();

    group.addObjects(geoPointsObjs);

    map.getViewModel().setLookAtData({
      bounds: group.getBoundingBox(),
    });
    //dormir un rato para hacer el zoom si no se duerme no funciona el zoom
    await sleep();

    map.setZoom(map.getZoom() - 1);

    setLocations(_locations);
    setCars(_cars);
    
    let _settingCars = []
    _cars.forEach(f =>{
      _settingCars = [
        ..._settingCars,
        {
          id: f?.id,
          latitude: null,
          longitude: null,
          nombre: f?.nombre
        }
      ]
    })

    setSettingCars(_settingCars)
  };

  useEffect(() => {
    let interval;

    if (!loading) {
      interval = setInterval(() => {
        setSeconds((seconds) => (seconds === 60 ? 0 : seconds + 1));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [loading]);

  useEffect(() => {
    const H = window.H;
    const defaultLayers = platform.createDefaultLayers();
    const _map = new H.Map(mapRef.current, defaultLayers.raster.normal.map, {
      center: { lat: 21.6565042, lng: -99.333183 },
      zoom: 4.0,
      pixelRatio: window.devicePixelRatio || 1,
    });
    const _behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(_map));
    const _ui = H.ui.UI.createDefault(_map, defaultLayers, "es-ES");

    _ui.getControl("mapsettings").setAlignment("top-left");
    _ui.getControl("zoom").setAlignment("top-left");
    _ui.getControl("scalebar").setVisibility(false);

    setUi(_ui);
    setMap(_map);
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted && map && location?.state?.length) {
      searchCar(location.state);
    }

    return () => {
      mounted = false;
    };
  }, [location, map]);

  useEffect(() => {
    if (cars.length) {
      const timerId = setInterval(async () => {
        map.removeObjects(map.getObjects());

        // console.log(points);
        await drawPonins(points);

        let _locations = [...locations];
        const ids = cars
          .filter((c) => c.selected)
          .map((c) => c.id)
          .join();
        const newLocations = await getNewRoutes(ids, false);
        //insertar nuevaas locaciones
        _locations = locations.map((l) => [
          ...l,
          newLocations.find((nl) => nl.info.id === l[0].info.id),
        ]);
        //quitar locaciones repetidas
        // _locations = locations.map((loc) => [
        //   ...new Map(
        //     loc.map((item) => [JSON.stringify(item["location"]), item])
        //   ).values(),
        // ]);

        _locations.forEach((routes) => {
          const start = routes[0].location.lat + "," + routes[0].location.lng;
          const end =
            routes[routes.length - 1].location.lat +
            "," +
            routes[routes.length - 1].location.lng;
          let routingParameters = {
            origin: start,
            destination: end,
            routingMode: "fast",
            transportMode: "car",
            return: "polyline",
          };

          if (
            routes.length > 2 &&
            routes[routes.length - 1].info.ioStatus[9] !== "t"
          ) {
            routingParameters.via = new H.service.Url.MultiValueQueryParameter(
              routes
                .filter((r, i) => i !== 0 && i !== routes.length - 1)
                .map((r) => r.location.lat + "," + r.location.lng)
            );
          }

          const router = platform.getRoutingService(null, 8);
          const endInfoo = routes[routes.length - 1].info;

          router.calculateRoute(
            routingParameters,
            (result) =>
              onResult(result, { startInfo: routes[0].info,endInfo: endInfoo}, _locations.length),
            (error) => console.log(error.message)
          );
        });

        setLocations(_locations);
      }, time);

      return () => clearInterval(timerId);
    }
  }, [cars, locations]);

  useEffect(() => {
    if (points.length && map && !loading) {
      drawPonins(points).catch((e) => console.log(e));
    }
  }, [points, map, loading]);

  return (
    <Div100vh>
      <div style={{ position: "relative", height: "100%" }}>
        <div style={{ width: "100%" }}>
          <AppBar title="Rastreo continuo" />
          <div style={{ position: "absolute", marginLeft: 250, zIndex: 999 }}>
            <Card
              style={{
                padding: 10,
                marginRight: 20,
                marginTop: 20,
                width: "55px",
                float: "right",
              }}
            >
              <Grid container spacing={1}>
                <Grid item>
                  <AccessTimeIcon />
                </Grid>
                <Grid item>
                  <Typography align="center">{seconds}</Typography>
                </Grid>
              </Grid>
            </Card>
          </div>
          <div style={{ height: "94vh" }}>
            <div ref={mapRef} />
          </div>
        </div>
        <BottomSheets
          open={open}
          car={car}
          onClose={() => setOpen(false)}
          hoursUtc={hoursUtc}
        />
      </div>
      <FullScreenLoaderView open={loading} />
    </Div100vh>
  );
};

export default MapContinuosTracking;
