import React, { useState, useEffect } from "react";
import Div100vh from "react-div-100vh";
import {
  TextField,
  Grid,
  Card,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import AppBar from "../../components/AppBar/AppBar";
import Container from "../../components/Container/Container";
import Services from "../../services";
import FullScreenLoaderView from "../../components/FullScreenLoaderView/FullScreenLoaderView";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const miStorage = window.localStorage;

const UnitsView = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [cars, setCars] = useState([]);

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      if (mounted) {
        try {
          const user = JSON.parse(miStorage.getItem("usuarioChekcar"));

          const response = await Services.post(
            "https://gps.chekcar.mx/admin/prueba",
            { usuario: user.id },
            false
          );

          setCars(response.data.map((c) => ({ ...c, selected: false })));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };

    getData();

    return () => {
      mounted = false;
    };
  }, []);

  const toDetail = (car) => {
    history.push({
      pathname: "/unidades/detalle",
      state: {
        car: {
          ano: car.ano,
          carga: car.carga,
          nombreChofer: car.chofer_nombre,
          telefonoChofer: car.chofer_telefono,
          color: car.color,
          consumoKm: car.consumoKm,
          costoCombustible: car.costoCombustible,
          idDispositivo: car.idDispositivo,
          imagenIcon: car.imagenIcon,
          marca: car.marca,
          modelo: car.modelo,
          nombre: car.nombre,
          numeroEconomico: car.numeroEconomico,
          placa: car.placa,
          serie: car.serie,
          velocidadLimite: car.velocidadLimite,
          ...car,
        },
      },
    });
  };

  return (
    <Div100vh>
      <AppBar title="Unidades" />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              label="Buscar"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card style={{ maxHeight: "70vh", overflowY: "auto" }}>
              {cars
                .filter(
                  (c) =>
                    c.marca.toUpperCase().includes(search.toUpperCase()) ||
                    c.modelo.toUpperCase().includes(search.toUpperCase()) ||
                    c.nombre.toUpperCase().includes(search.toUpperCase())
                )
                .map((car, index) => (
                  <ListItem key={index} onClick={() => toDetail(car)}>
                    <ListItemText
                      primary={car.nombre}
                      secondary={car.marca + " " + car.modelo}
                    />
                    <ListItemIcon>
                      <IconButton style={{ color: "#1388ca" }}>
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                ))}
            </Card>
          </Grid>
        </Grid>
      </Container>
      <FullScreenLoaderView open={loading} />
    </Div100vh>
  );
};

export default UnitsView;
