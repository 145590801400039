import React, { useEffect, useState } from "react";
import {
  Card,
  TextField,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Avatar,
  IconButton,
} from "@material-ui/core";
import Div100vh from "react-div-100vh";
import AppBar from "../../components/AppBar/AppBar";
import Container from "../../components/Container/Container";
import FullScreenLoaderView from "../../components/FullScreenLoaderView/FullScreenLoaderView";
import Services from "../../services";
import RoomIcon from "@material-ui/icons/Room";
import Alert from "../../components/Alert";
import { Search } from "@material-ui/icons";

const miStorage = window.localStorage;

const PointsView = ({ history }) => {
  const [search, setSearch] = useState("");
  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      if (mounted) {
        try {
          const { idCuenta, id } = JSON.parse(
            miStorage.getItem("usuarioChekcar")
          );

          const response = await Services.get2(
            "geocerca/puntos?idCuenta=" + idCuenta + "&idUsuario=" + id
          );

          if (response.puntos) {
            setPoints(response.puntos);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };

    getData();

    return () => {
      mounted = false;
    };
  }, []);

  const toMap = () => {
    if (!points.some((c) => c.selected)) {
      setError("Favor de seleccionar una unidad.");
      setTimeout(() => {
        setError("");
      }, 2500);

      return;
    }

    history.push({
      pathname: "/puntos/mapa",
      state: points.filter((p) => p.selected),
    });
  };

  return (
    <Div100vh>
      <AppBar title="Puntos" />
      <Container>
        <TextField
          size="small"
          variant="filled"
          fullWidth
          label="Buscar"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Card
          style={{
            marginTop: 20,
            paddingBottom: 10,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          {points
            .filter((c) =>
              c.nombre.toUpperCase().includes(search.toUpperCase())
            )
            .map((p) => (
              <div key={p?.id}>
                <ListItem>
                  <ListItemIcon>
                    <RoomIcon style={{ color: "#1388ca" }} />
                  </ListItemIcon>
                  <ListItemText primary={p.nombre} />
                  <ListItemIcon>
                    <Switch
                      onChange={() =>
                        setPoints( points =>
                          points.map(c => 
                          p?.id === c?.id 
                            ? {
                              ...c,
                              selected:  !c.selected,
                              }
                            : c
                          )
                        )
                      }
                      checked={p.selected}
                      color="primary"
                    />
                  </ListItemIcon>
                </ListItem>
              </div>
            ))}
        </Card>
        <Avatar
          style={{
            backgroundColor: "#1388ca",
            cursor: "pointer",
            position: "absolute",
            right: 0,
            bottom: 0,
            marginBottom: 10,
            marginRight: 10,
          }}
          onClick={() => toMap()}
        >
          <Search />
        </Avatar>
      </Container>
      <FullScreenLoaderView open={loading} />
      <Alert
        open={Boolean(error)}
        onClose={() => setError("")}
        severity="error"
        message={error}
      />
    </Div100vh>
  );
};

export default PointsView;
