import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Email as EmailIcon } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3,0,1,0)
  },
  icon: {
    fontSize: "14px",
  }
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography style={{fontSize: 12}}>Derechos resevados <b>CHEKCAR {new Date().getFullYear()}</b> ® &nbsp;&nbsp;| &nbsp;</Typography>
      <EmailIcon className={classes.icon} color="secondary"/> &nbsp;&nbsp;
      <Typography style={{fontSize: 12}}>Contacto</Typography>
    </div>
  );
};

export default Footer;