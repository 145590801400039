import { createTheme } from '@material-ui/core/styles';
export default createTheme({
    palette: {
      primary: {
        main: '#1388ca'
      },
      secondary: {
        main: '#939292'
      }
    },
    overrides: {
      MuiButton: {
        containedSecondary: {
          color: '#ffffff',
        },
      }
    }
});