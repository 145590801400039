import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tooltip,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  Divider,
  Drawer,
  Typography,
} from "@material-ui/core";
import {
  AutorenewOutlined,
  DirectionsCarOutlined,
  ExitToApp,
  GpsFixedOutlined,
  GroupWork,
  GroupWorkOutlined,
  HistoryOutlined,
  LocalGasStationOutlined,
  LocationOnOutlined,
  MapOutlined,
  MyLocationOutlined,
  NotificationsOutlined,
  ShareOutlined,
  TrackChangesOutlined,
  TuneOutlined,
} from "@material-ui/icons";
import CollapsableList from "./CollapsableList";
import { useToken } from "../hooks/useToken";
import logo from "../../src/white-logo.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  doubleNested: {
    paddingLeft: theme.spacing(6),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
}));

const CustomDrawer = ({ isOpen, toggleIsOpen }) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    setUserAuth,
    setToken,
    userAuth,
    showDrawer,
    setShowDrawer,
    setPoints,
  } = useToken();

  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const myStorage = window.localStorage;
    const user = JSON.parse(myStorage.getItem("usuarioChekcar"));
    setUser(user);
  }, []);

  const vehicleTracking = {
    title: "Rastreo vehicular",
    icon: <TrackChangesOutlined />,
    links: [
      {
        to: "/rastreo-continuo",
        icon: <AutorenewOutlined />,
        title: "Rastreo Continuo",
      },
      {
        to: "/historico",
        icon: <HistoryOutlined />,
        title: "Histórico",
      },
    ],
  };

  const georeferences = {
    icon: <MyLocationOutlined />,
    title: "Georeferencias",
    links: [
      {
        to: "/geocercas",
        icon: <GpsFixedOutlined />,
        title: "Geocercas",
      },
      {
        to: "/grupos-puntos",
        icon: <GroupWorkOutlined />,
        title: "Grupos de Puntos",
      },
      {
        to: "/puntos",
        icon: <LocationOnOutlined />,
        title: "Puntos",
      },
    ],
  };

  const metricsLink = {
    to: "/unidades",
    icon: <DirectionsCarOutlined />,
    title: "Unidades",
  };

  const shareTrackingLink = {
    to: "/compartir-rastreo",
    icon: <ShareOutlined />,
    title: "Compartir Rastreo",
  };

  const configurations = {
    icon: <TuneOutlined />,
    title: "Notificación Push",
    links: [
      {
        to: "/notificaciones-eventos",
        icon: <GpsFixedOutlined />,
        title: "Eventos",
      },
    ],
  };

  const renderLink = (link) => (
    <NavLink to={link.to} style={{ textDecoration: "none", color: "black" }}>
      <Tooltip placement="right" title={link.title} arrow>
        <ListItem onClick={() => setShowDrawer(!showDrawer)} button>
          <ListItemIcon>{link.icon}</ListItemIcon>
          <ListItemText
            primary={link.title}
            style={{ whiteSpace: "pre-wrap" }}
          />
        </ListItem>
      </Tooltip>
    </NavLink>
  );

  return (
    <Drawer open={showDrawer} onClose={() => setShowDrawer(!showDrawer)}>
      <img height={60} style={{ objectFit: "cover", padding: 10 }} src={logo} />
      <Typography align="center">{user?.usuario || "cargando..."}</Typography>
      <Divider />
      <List component="nav" className={classes.root}>
        <CollapsableList
          icon={vehicleTracking.icon}
          title={vehicleTracking.title}
          links={vehicleTracking.links}
        />
        <CollapsableList
          icon={georeferences.icon}
          title={georeferences.title}
          links={georeferences.links}
        />
        <ListItem
          button
          onClick={() => {
            setPoints([]);
            setShowDrawer(!showDrawer);
          }}
        >
          <ListItemIcon>
            <MapOutlined />
          </ListItemIcon>
          <ListItemText primary="Limpiar Mapa" />
        </ListItem>
        <Divider />
        {renderLink(metricsLink)}

        {renderLink(shareTrackingLink)}
        <Divider />
        <CollapsableList
          icon={configurations.icon}
          title={configurations.title}
          links={configurations.links}
        />
        <div
          onClick={() => {
            setToken(null);
            setUserAuth(null);
            const myStorage = window.localStorage;
            myStorage.clear();
            history.push("/login");
          }}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem button>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText secondary="CERRAR SESIÓN" />
          </ListItem>
        </div>
      </List>
      <div style={{ fontFamily: 'verdana', fontSize: 10, width: '100%', textAlign: 'center', position: 'absolute', bottom: 10 }}>
          v1.0.10
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
