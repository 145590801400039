import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './App.css';
import { TokenProvider } from './hooks/useToken';

ReactDOM.render(
  <TokenProvider>
    <App />
  </TokenProvider>,
  document.getElementById('root')
);