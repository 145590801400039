import Div100vh from "react-div-100vh";
import React, { useRef, useState, useEffect } from "react";
import FullScreenLoaderView from "../../components/FullScreenLoaderView/FullScreenLoaderView";
import AppBar from "../../components/AppBar/AppBar";
import Services from "../../services";
import HistoryMarker from "./HistoryMarker";
import GoogleMarker from "./GoogleMarker";
import BottomSheets from "./BottomSheets";
import Alert from "../../components/Alert";
import iconsTypeByEvent from "./IconsTypeByEvent";
import moment from "moment";
import { eventos } from "../../constants";
import googleMarker from '../../cars/red-marker.png';
import { useToken } from "../../hooks/useToken";
import { AmpStories } from "@material-ui/icons";

const platform = new H.service.Platform({
  apikey: "kUsKaAJJxXkSBces1hmFjNrIPBKRjZyXezk66tCyhXk",
});

const now = new Date();
const utc = now.getTimezoneOffset().toString();
const hoursUtc = parseFloat(utc) / 60;

const MapHistoricalView = ({ location }) => {
  const { token2} = useToken();
  const mapRef = useRef();
  const [history, setHistory] = useState([]);
  const [map, setMap] = useState(null);
  const [markerRow, setMarkerRow] = useState(null);
  const [showListHistory, setShowListHistory] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [ui, setUi] = useState(null)
  const [imgIcon, setImgIcon] = useState("")
  const [imgIcon2, setImgIcon2] = useState("")

  useEffect(() => {
    const H = window.H;
    const defaultLayers = platform.createDefaultLayers();
    const _map = new H.Map(mapRef.current, defaultLayers.raster.normal.map, {
      center: { lat: 21.6565042, lng: -99.333183 },
      zoom: 4.0,
      pixelRatio: window.devicePixelRatio || 1,
    });
    const _behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(_map));
    const _ui = H.ui.UI.createDefault(_map, defaultLayers, "es-ES");

    _ui.getControl("mapsettings").setAlignment("top-left");
    _ui.getControl("zoom").setAlignment("top-left");
    _ui.getControl("scalebar").setVisibility(false);

    setUi(_ui)
    setMap(_map);
  }, []);

  useEffect(() => {
    if (location?.state && map) {
      const { hora, car, startDate, endDate } = location?.state;
      searchReport(hora, car.id, startDate, endDate);
    }
  }, [location, map]);

  const renderDateUTC = prop => {
    if (prop) {
      // ? UTC del navegador
      const date = new Date(prop);
      const dateUTC = new Date(
        date.getTime() - date.getTimezoneOffset() * 60 * 1000
      );
      return moment(dateUTC).format("YYYY-MM-DD HH:mm:ss");
    }
    return sinDefinir;
  }

  const searchReport = async (hours, idCar, startDate, endDate) => {
    try {
      const currentDate = new Date();
      let _diaActual = "";
      _diaActual = moment(currentDate).format("YYYY-MM-DD");
      const finalDate = new Date(currentDate.getTime() - hours * 60 * 60 * 1000)
      const endDate = {
        2 : moment(finalDate).format("YYYY-MM-DD HH:mm:ss"),
        4 : moment(finalDate).format("YYYY-MM-DD HH:mm:ss"),
        8 : moment(finalDate).format("YYYY-MM-DD HH:mm:ss"),
        24 : moment(currentDate).format("YYYY-MM-DD") + " 00:00:00",
        48 : moment( moment(currentDate).format("YYYY-MM-DD") + " 00:00:00").subtract(1, 'days').format("YYYY-MM-DD") + " 00:00:00",
      };

      const responseDevice = await Services.post3("map/historico-posiciones-op",{
        descargar: 0,
        dispositivos: [idCar],
        fechaFin: 
          parseInt(hours) === 48 
            ? moment(endDate[hours]).format("YYYY-MM-DD") + " 23:59:59"
            :  moment(currentDate).format("YYYY-MM-DD HH:mm:ss"),
        fechaInicio: endDate[hours],
        timeoffset: hoursUtc
      }, token2);
      let _locations = [];
      let geoPointsObjs = [];

      responseDevice.data.result[idCar].datos[0].ubicacion.forEach((d) => {
        const loc = {
          lat: parseFloat(d.latitude),
          lng: parseFloat(d.longitude),
        };
        
        geoPointsObjs.push(new H.map.Marker(loc));
        _locations.push([{ 
          info: {
            ...d,
            timestamp: renderDateUTC(d?.timestamp),
          }, 
          location: loc,
          timestamp: renderDateUTC(d?.timestamp),
        }]);
      });

      setImgIcon2(responseDevice.data.result[idCar].dispositivo.imageIcon.split("/")[responseDevice.data.result[idCar].dispositivo.imageIcon.split("/").length - 1])

      if (_locations?.length === 0) {
        setError("Esta unidad no tienen histórico este dia.");
        setLoading(false);
        return;
      }
      _locations.filter((r, i) => {
        if(
          parseInt(hours) === 48 &&
          _diaActual !== "" && 
          moment(r[0].info?.timestamp).format("YYYY-MM-DD") === _diaActual
        ){
          console.log("entro", r[0].info?.timestamp)
          return false
        }else if (i === 0 && parseInt(r[0].info?.speed) > 5) {
          return true;
        } else if (
          parseInt(r[0].info?.speed) <= 5
        ) {
          return false;
        } else {
          return true;
        }
      });

      if (!_locations.length) {
        _locations = [_locations[_locations.length - 1]];
      }

      if(_locations.length > 750 && _locations.length <= 1000){
        const routes1 = _locations.slice(0, 250);
        const routes2 = _locations.slice(250, 500);
        const routes3 = _locations.slice(500, 750);
        const routes4 = _locations.slice(750, _locations.length);

        const start1 = routes1[0][0].info.latitude + "," + routes1[0][0].info.longitude;
        const end1 =
          routes1[routes1.length - 1][0].info.latitude +
          "," +
          routes1[routes1.length - 1][0].info.longitude;

        const start2 = routes2[0][0].info.latitude + "," + routes2[0][0].info.longitude;
        const end2 =
          routes2[routes2.length - 1][0].info.latitude +
          "," +
          routes2[routes2.length - 1][0].info.longitude;

        const start3 = routes3[0][0].info.latitude + "," + routes3[0][0].info.longitude;
        const end3 =
          routes3[routes3.length - 1][0].info.latitude +
          "," +
          routes3[routes3.length - 1][0].info.longitude;

        const start4 = routes4[0][0].info.latitude + "," + routes4[0][0].info.longitude;
        const end4 =
          routes4[routes4.length - 1][0].info.latitude +
          "," +
          routes4[routes4.length - 1][0].info.longitude;

        const routingParameters1 = {
          origin: start1,
          destination: end1,
          routingMode: "fast",
          transportMode: "car",
          return: "polyline",
          via: new H.service.Url.MultiValueQueryParameter(
            routes1
              .filter((r, i) => i !== 0 && i !== routes1.length - 1)
              .map((r) => r[0].info.latitude + "," + r[0].info.longitude)
          ),
        };

        const routingParameters2 = {
          origin: start2,
          destination: end2,
          routingMode: "fast",
          transportMode: "car",
          return: "polyline",
          via: new H.service.Url.MultiValueQueryParameter(
            routes2
              .filter((r, i) => i !== 0 && i !== routes2.length - 1)
              .map((r) => r[0].info.latitude + "," + r[0].info.longitude)
          ),
        };

        const routingParameters3 = {
          origin: start3,
          destination: end3,
          routingMode: "fast",
          transportMode: "car",
          return: "polyline",
          via: new H.service.Url.MultiValueQueryParameter(
            routes3
              .filter((r, i) => i !== 0 && i !== routes3.length - 1)
              .map((r) => r[0].info.latitude + "," + r[0].info.longitude)
          ),
        };

        const routingParameters4 = {
          origin: start4,
          destination: end4,
          routingMode: "fast",
          transportMode: "car",
          return: "polyline",
          via: new H.service.Url.MultiValueQueryParameter(
            routes4
              .filter((r, i) => i !== 0 && i !== routes4.length - 1)
              .map((r) => r[0].info.latitude + "," + r[0].info.longitude)
          ),
        };

        const router = platform.getRoutingService(null, 8);

        router.calculateRoute(
          routingParameters1,
          (result) => onResultHistory(result, true, false, _locations, responseDevice.data.result[idCar].dispositivo.imageIcon.split("/")[responseDevice.data.result[idCar].dispositivo.imageIcon.split("/").length - 1]),
          (error) => console.log(error.message)
        );

        router.calculateRoute(
          routingParameters2,
          (result) => onResultHistory(result, false, true, _locations, responseDevice.data.result[idCar].dispositivo.imageIcon.split("/")[responseDevice.data.result[idCar].dispositivo.imageIcon.split("/").length - 1]),
          (error) => console.log(error.message)
        );

        router.calculateRoute(
          routingParameters3,
          (result) => onResultHistory(result, false, false, _locations, responseDevice.data.result[idCar].dispositivo.imageIcon.split("/")[responseDevice.data.result[idCar].dispositivo.imageIcon.split("/").length - 1]),
          (error) => console.log(error.message)
        );

        router.calculateRoute(
          routingParameters4,
          (result) => onResultHistory(result, true, true, _locations, responseDevice.data.result[idCar].dispositivo.imageIcon.split("/")[responseDevice.data.result[idCar].dispositivo.imageIcon.split("/").length - 1]),
          (error) => console.log(error.message)
        );
        
      } else if (_locations.length > 500 && _locations.length <= 750) {
        const routes1 = _locations.slice(0, 250);
        const routes2 = _locations.slice(250, 500);
        const routes3 = _locations.slice(500, _locations.length);

        const start1 = routes1[0][0].info.latitude + "," + routes1[0][0].info.longitude;
        const end1 =
          routes1[routes1.length - 1][0].info.latitude +
          "," +
          routes1[routes1.length - 1][0].info.longitude;

        const start2 = routes2[0][0].info.latitude + "," + routes2[0][0].info.longitude;
        const end2 =
          routes2[routes2.length - 1][0].info.latitude +
          "," +
          routes2[routes2.length - 1][0].info.longitude;

        const start3 = routes3[0][0].info.latitude + "," + routes3[0][0].info.longitude;
        const end3 =
          routes3[routes3.length - 1][0].info.latitude +
          "," +
          routes3[routes3.length - 1][0].info.longitude;

        const routingParameters1 = {
          origin: start1,
          destination: end1,
          routingMode: "fast",
          transportMode: "car",
          return: "polyline",
          via: new H.service.Url.MultiValueQueryParameter(
            routes1
              .filter((r, i) => i !== 0 && i !== routes1.length - 1)
              .map((r) => r[0].info.latitude + "," + r[0].info.longitude)
          ),
        };

        const routingParameters2 = {
          origin: start2,
          destination: end2,
          routingMode: "fast",
          transportMode: "car",
          return: "polyline",
          via: new H.service.Url.MultiValueQueryParameter(
            routes2
              .filter((r, i) => i !== 0 && i !== routes2.length - 1)
              .map((r) => r[0].info.latitude + "," + r[0].info.longitude)
          ),
        };

        const routingParameters3 = {
          origin: start3,
          destination: end3,
          routingMode: "fast",
          transportMode: "car",
          return: "polyline",
          via: new H.service.Url.MultiValueQueryParameter(
            routes3
              .filter((r, i) => i !== 0 && i !== routes3.length - 1)
              .map((r) => r[0].info.latitude + "," + r[0].info.longitude)
          ),
        };

        const router = platform.getRoutingService(null, 8);

        router.calculateRoute(
          routingParameters1,
          (result) => onResultHistory(result, true, false, _locations, responseDevice.data.result[idCar].dispositivo.imageIcon.split("/")[responseDevice.data.result[idCar].dispositivo.imageIcon.split("/").length - 1]),
          (error) => console.log(error.message)
        );

        router.calculateRoute(
          routingParameters2,
          (result) => onResultHistory(result, false, true, _locations, responseDevice.data.result[idCar].dispositivo.imageIcon.split("/")[responseDevice.data.result[idCar].dispositivo.imageIcon.split("/").length - 1]),
          (error) => console.log(error.message)
        );

        router.calculateRoute(
          routingParameters3,
          (result) => onResultHistory(result, false, false, _locations, responseDevice.data.result[idCar].dispositivo.imageIcon.split("/")[responseDevice.data.result[idCar].dispositivo.imageIcon.split("/").length - 1]),
          (error) => console.log(error.message)
        );
      } else if (_locations.length > 250 && _locations.length <= 500) {
        const routes1 = _locations.slice(0, 250);
        const routes2 = _locations.slice(250, _locations.length);

        const start1 = routes1[0][0].info.latitude + "," + routes1[0][0].info.longitude;
        const end1 =
          routes1[routes1.length - 1][0].info.latitude +
          "," +
          routes1[routes1.length - 1][0].info.longitude;

        const start2 = routes2[0][0].info.latitude + "," + routes2[0][0].info.longitude;
        const end2 =
          routes2[routes2.length - 1][0].info.latitude +
          "," +
          routes2[routes2.length - 1][0].info.longitude;

        const routingParameters1 = {
          origin: start1,
          destination: end1,
          routingMode: "fast",
          transportMode: "car",
          return: "polyline",
          via: new H.service.Url.MultiValueQueryParameter(
            routes1
              .filter((r, i) => i !== 0 && i !== routes1.length - 1)
              .map((r) => r[0].info.latitude + "," + r[0].info.longitude)
          ),
        };

        const routingParameters2 = {
          origin: start2,
          destination: end2,
          routingMode: "fast",
          transportMode: "car",
          return: "polyline",
          via: new H.service.Url.MultiValueQueryParameter(
            routes2
              .filter((r, i) => i !== 0 && i !== routes2.length - 1)
              .map((r) => r[0].info.latitude + "," + r[0].info.longitude)
          ),
        };

        const router = platform.getRoutingService(null, 8);

        router.calculateRoute(
          routingParameters1,
          (result) => onResultHistory(result, true, false, _locations, responseDevice.data.result[idCar].dispositivo.imageIcon.split("/")[responseDevice.data.result[idCar].dispositivo.imageIcon.split("/").length - 1]),
          (error) => console.log(error.message)
        );

        router.calculateRoute(
          routingParameters2,
          (result) => onResultHistory(result, false, true, _locations, responseDevice.data.result[idCar].dispositivo.imageIcon.split("/")[responseDevice.data.result[idCar].dispositivo.imageIcon.split("/").length - 1]),
          (error) => console.log(error.message)
        );
      } else if (_locations.length <= 250) {
        console.log("less than 250", _locations);
        console.log("less than 250", _locations[0][0].info.latitude + "," + _locations[0][0].info.longitude);
        const start = _locations[0][0].info.latitude + "," + _locations[0][0].info.longitude;
        const end =
          _locations[_locations.length - 1][0].info.latitude +
          "," +
          _locations[_locations.length - 1][0].info.longitude;

        let routingParameters = {
          origin: start,
          destination: end,
          routingMode: "fast",
          transportMode: "car",
          return: "polyline",
        };

        if (_locations.length > 2) {
          routingParameters.via = new H.service.Url.MultiValueQueryParameter(
            _locations
              .filter((r, i) => i !== 0 && i !== _locations.length - 1)
              .map((r) => r[0].info.latitude + "," + r[0].info.longitude)
          );
        }

        const router = platform.getRoutingService(null, 8);

        router.calculateRoute(
          routingParameters,
          (result) => onResultHistory(result, true, true, _locations, responseDevice.data.result[idCar].dispositivo.imageIcon.split("/")[responseDevice.data.result[idCar].dispositivo.imageIcon.split("/").length - 1]),
          (error) => console.log(error.message)
        );
      }
      setHistory(_locations.map((r) => ({ ...r, selected: false })));
      
      const group = new window.H.map.Group();
      group.addObjects(geoPointsObjs);
      map.addObject(group);
      map.getViewModel().setLookAtData({
        bounds: group.getBoundingBox(),
      });
      geoPointsObjs.forEach((geoPoint) => {
        geoPoint.setVisibility(false);
      })
    } catch (error) {
      console.log(error);
    }
  };

  const getFirstOrLastData = async(end, imageName, idCar, minutes) => {
    let numbers = []
    let search = 0
    let response = await Services.historicalData(idCar, (minutes*60))
    if(response.data){
      response.data.map(m=> {
        numbers.push(m.id)
      })
      if(end){
        search = Math.max.apply(null, numbers)
        return response.data.find(f=> f.id == search).speed > 5
          ? `https://gps.chekcar.mx/img/imagesDevices/green/${imgIcon2}` 
          : `https://gps.chekcar.mx/img/imagesDevices/red/${imgIcon2}`
      }else{
        search = Math.min.apply(null, numbers)
        return response.data.find(f=> f.id == search).speed > 5 
          ? `https://gps.chekcar.mx/img/imagesDevices/green/${imgIcon2}` 
          : `https://gps.chekcar.mx/img/imagesDevices/red/${imgIcon2}`
      }
    }
  }

  const onResultHistory = async(result, withStart, withEnd, locations, img) => {

    if (result.routes.length) {
      const { hora, car } = location?.state;
      // setImgIcon(locations[0][0].info.imageIcon.split("/")[3])
      const _first = locations[0][0].info.speed > 5 ? `https://gps.chekcar.mx/img/imagesDevices/green/${img}` : `https://gps.chekcar.mx/img/imagesDevices/red/${img}`
      const _last = locations[locations.length - 1][0].info.speed > 5 ? `https://gps.chekcar.mx/img/imagesDevices/green/${img}` : `https://gps.chekcar.mx/img/imagesDevices/red/${img}`
      const finalLoc = { lat: 0.0, lng: 0.0 };

      result.routes[0].sections.forEach(async (section, index) => {
        const linestring = H.geo.LineString.fromFlexiblePolyline(
          section.polyline
        );
        const routeLine = new H.map.Polyline(linestring, {
          style: {
            lineWidth: 10,
            strokeColor: "rgba(0, 128, 255, 0.7)",
            lineTailCap: "arrow-tail",
            lineHeadCap: "arrow-head",
          },
        });
        const routeArrows = new H.map.Polyline(linestring, {
          style: {
            lineWidth: 10,
            fillColor: "white",
            strokeColor: "rgba(255, 255, 255, 1)",
            lineDash: [0, 2],
            lineTailCap: "arrow-tail",
            lineHeadCap: "arrow-head",
          },
        });
      
        const domIconStart = HistoryMarker(_first, locations[0][0].info, "I");
        const domIconEnd = HistoryMarker(_last, locations[locations.length-1][0].info, "F");

        const startLocation = section.departure.place.location;
        const endLocation = section.arrival.place.location;

        finalLoc.lat += (startLocation.lat + endLocation.lat) / 2;
        finalLoc.lng += (startLocation.lng + endLocation.lng) / 2;

        const startMarker = new H.map.DomMarker(startLocation, {
          icon: new H.map.DomIcon(domIconStart),
        });
        const endMarker = new H.map.DomMarker(endLocation, {
          icon: new H.map.DomIcon(domIconEnd),
        });

        let objs = [];

        if (result.routes[0].sections.length === 1) {
          objs = [routeLine, startMarker, endMarker];
        } else {
          if (index === 0 && withStart) {
            objs = [routeLine, routeArrows, startMarker];
          } else if (
            index === result.routes[0].sections.length - 1 &&
            withEnd
          ) {
            objs = [routeLine, routeArrows, endMarker];
          } else {
            objs = [routeLine, routeArrows];
          }
        }
        // var container = new H.map.Group({
        //   objects: [...objs],
        // });
        // container.addEventListener("onchange", function (evt) {
        //   var target = evt.target;
        //   // retrieve maximum zoom level
        //   var minZoom = target.getData().minZoom;
        //   // get the shape's bounding box and adjust the camera position
        //   console.log("setting zoom");
        //   map.getViewModel().setLookAtData({
        //     zoom: minZoom,
        //     bounds: target.getBoundingBox(),
        //   });
        // });
        // container.dispatchEvent("ready");
        map.addObjects(objs);
      });
      const dataLength = result.routes[0].sections.length;
      finalLoc.lat = finalLoc.lat / dataLength;
      finalLoc.lng = finalLoc.lng / dataLength;
      // console.log("final: ", finalLoc);
      // map.setCenter(finalLoc);
      // map.setZoom(14);
    }

    setLoading(false);
  };

  const getUrlStreetMap = location => "https://maps.google.com/maps?q=&layer=c&cbll=" + location.lat + "," + location.lng;

  const getUrlMaps = location => "https://maps.google.com/maps?q=" + location.lat + "," + location.lng;

  const getEvent = (data) => {
    if(data?.image?.length > 3){ 
      return ` <img src="https://gps.chekcar.mx${data?.image}" width="25px"/><br />`
    }else return ""
  }

  const getEventDescription = (data) => {
    if(data && data[0].info.DescripcionEvento?.length && data[0].info.DescripcionEvento?.length > 3) return data[0].info.DescripcionEvento
    else if(iconsTypeByEvent.find(d => d.id === data[0].info.eventType)?.descripcion) return iconsTypeByEvent.find(d => d.id === data[0].info.eventType)?.descripcion
    else return iconsTypeByEvent.find(d => d.id === 0)?.descripcion
  }

  const getImgCarData = async(imageName, speed) => {
    return speed > 5 
      ? `https://gps.chekcar.mx/img/imagesDevices/green/${imageName}` 
      : `https://gps.chekcar.mx/img/imagesDevices/red/${imageName}`
  }

  const toPositionRowTable = async(location, idPoint) => {
    const reponseGeoCoder = await Services.geoCoder(location?.lat, location?.lng);
    const data = reponseGeoCoder.data;
    const address = data["Response"]["View"][0]["Result"][0]["Location"]["Address"]["Label"]
    
    setHistory(history =>
      history.map((h) => ({
        ...h,
        selected: idPoint == h[0].info.id ? !h.selected : false,
      })).sort((a, b) => a[0].info.id < b[0].info.id ? 1 : -1)
    );

    const exactData = history.find((v) => v[0].info.id === idPoint )  

    if (markerRow) {
      map.removeObject(markerRow);
    }
    const _img = await getImgCarData(imgIcon2, exactData[0].info.speed)

    const marker = new H.map.DomMarker(location, {
      icon: new H.map.DomIcon(GoogleMarker(_img)), //before: exactData
    });

    const contentBubble ="<div style='width: 150px'>" +
    getEvent(exactData[0].info) +
    "<label> <b>Evento:</b> " +
    getEventDescription(exactData) +
    "</label>" +
    "<label><br /> <b>Fecha:</b> " +
    moment(exactData[0].info.timestamp).format("DD/MM/YYYY") +
    "</label>" +
    "<br />" +
    "<label> <b>Hora:</b> " +
    moment(exactData[0].info.timestamp).format("hh:mm a") +
    "</label>" +
    "<br />" +
    "<label> <b>Dirección:</b> " +
    address +
    ". </label>" +
    "<br />" +
    "<label> <b>Velocidad:</b> " +
    parseFloat(exactData[0].info.speed).toFixed(0) +
    " Km/h</label>" +
    "<br />" +
    "<label> <b>Voltaje:</b> " +
    exactData[0].info.powerVoltage +
    " V</label>" +
    "<br />" +
    "<li><a href=" +
    getUrlStreetMap(location) +
    " target='_blank' style='color:#116EBF'> Vista en calle </a></li> " +
    "<li><a href=" +
    getUrlMaps(location) +
    " target='_blank' style='color:#116EBF'> Google Maps </a></li>" +
    "</div>";

    const bubble = new H.ui.InfoBubble(location);
    bubble.setContent(contentBubble);

    bubble.addEventListener("statechange", (evt) => {
      console.log("statechange: ", evt);
      console.log("statechange: ", evt.target.getState());
      if (evt.target.getState() === H.ui.InfoBubble.State.CLOSED) {
        if (ui.getBubbles().length) {
          ui.getBubbles().forEach((bub) => ui.removeBubble(bub));
        }
      }
      if (evt.target.getState() === H.ui.InfoBubble.State.OPEN) {
        if (ui.getBubbles().length) {
          ui.getBubbles().forEach((bub) => bub.u.lat !== location.lat  && bub.u.lng !== location.lng 
            ? bub.setState("close") 
            : null
          )
        }
      }
    });
    ui.addBubble(bubble)
    if (ui?.getBubbles()?.length) {
      ui.getBubbles().forEach((bub) => bub.u.lat == location.lat  && bub.u.lng == location.lng 
        ? bub.setState("open") 
        : bub.setState("close")
      );
      ui.getBubbles().forEach((bub) => bub.u.lat != location.lat  && bub.u.lng != location.lng 
        ? bub.setState("close") 
        : bub.setState("open")
      );
    }

    map.addObject(marker);
    map.setCenter(location);
    map.setZoom(18);

    setMarkerRow(marker);
  };

  return (
    <Div100vh>
      <div style={{ position: "relative", height: "100%" }}>
        <>
          <AppBar title="Histórico" />
          <div style={{ height: "94vh" }}>
            <div ref={mapRef} />
          </div>
        </>
        <BottomSheets
          propHistory={history}
          setShowListHistory={(newValue) => setShowListHistory(newValue)}
          showListHistory={showListHistory}
          toPositionRowTable={toPositionRowTable}
        />
      </div>
      <FullScreenLoaderView open={loading} />
      <Alert
        open={Boolean(error)}
        onClose={() => setError("")}
        severity="error"
        message={error}
      />
    </Div100vh>
  );
};

export default MapHistoricalView;
