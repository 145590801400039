import HomeView from "./HomeView/HomeView";
import LogInView from "./LoginView/index";
import PrivacyPolicyView from "./PrivacyPolicy/PrivacyPolicy";
import ContinuosTrackingView from "./ContinuosTracking/ContinuosTrackingView";
import MapContinuosTrackingView from "./ContinuosTracking/MapContinuosTrackingView";
import HistoricalView from "./Historical/HistoricalView";
import MapHistoricalView from "./Historical/MapHistoricalView";
import UnitsView from "./Units/UnitsView";
import DetailUnitView from "./Units/DetailUnitView";
import UnitsGroupsView from "./UnitsGroups/UnitsGroupsView";
import PointsView from "./Points/PointsView";
import MapPointsView from "./Points/MapPointsView";
import GeofencesView from "./Geofences/GeofencesView";
import MapGeofencesView from "./Geofences/MapGeofencesView";
import PonitsGroupView from "./PointsGroup/PointsGroupView";
import MapPointsGroupsView from "./PointsGroup/MapPointsGroupsView";
import ShareTrackingView from "./ShareTracking/ShareTrackingView";
import ShareView from "./ShareTracking/ShareView";
import ShareContinuosTrackingView from "./ContinuosTracking/ShareContinuosTrackingView";
import FinishShareTrackingView from "./ContinuosTracking/FinishShareTrackingView";

export {
  HomeView,
  LogInView,
  PrivacyPolicyView,
  ContinuosTrackingView,
  MapContinuosTrackingView,
  HistoricalView,
  MapHistoricalView,
  UnitsView,
  DetailUnitView,
  PointsView,
  MapPointsView,
  UnitsGroupsView,
  GeofencesView,
  MapGeofencesView,
  PonitsGroupView,
  MapPointsGroupsView,
  ShareTrackingView,
  ShareView,
  ShareContinuosTrackingView,
  FinishShareTrackingView,
};
