import React, { useEffect, useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import RoomIcon from '@material-ui/icons/Room';

const BottomSheets = ({group, paramPoints = [], setMap}) => {
  const [points, setPoints] = useState([]);

  useEffect(() => {
    if(paramPoints) {
      setPoints(paramPoints);
    }
  }, [paramPoints]);
  
  const toPoint = (point) => {
    setPoints(points.map(p => ({...p, selected: p.id === point.id})));
    setMap({lat: point.lat, lon: point.lon});
  }

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "white",
        height: "22vh",
        zIndex: 999,
        overflowY: "auto",
        overflowX: "hidden"
      }}
    >
      <ListItem style={{marginTop: -10, marginBottom: -10}}>
        <ListItemIcon><GroupWorkIcon style={{color: group.color}} /></ListItemIcon>
        <ListItemText primary={group.nombre} secondary={group.descripcion} />
      </ListItem>
      <hr style={{width: "98%" , margin: 0, padding: 0}} />
      {
        points.map((p) => (
          <ListItem 
            key={p.id} 
            style={{marginTop: -10, marginBottom: -20}}
            onClick={() => toPoint(p)} 
            style={{ backgroundColor: p.selected ? "#e0e0e0" : "white"}}
          >
            <ListItemIcon><RoomIcon style={{color: p.color}} /></ListItemIcon>
            <ListItemText primary={p.nombre} secondary={"Nombre"} />
          </ListItem>
        ))
      }
    </div>
  )
}

export default BottomSheets;