import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from './../../components/Alert';
import logo from './../../white-logo.png';
import LoginForm from './LoginForm';
import Footer from './Footer'; 
import Services from '../../services';
import { useToken } from '../../hooks/useToken';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    maxWidth: '950px',
    margin: '0 auto',
    paddingLeft: '100px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
    }
  },
  root: {
    backgroundColor: '#fafafa'
  },
  logo: {
    width: '240px',
    marginTop: '-75px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '40'
    }
  }
}));

const LoginView = ({history}) => {
  const { setToken, setToken2, setUserAuth} = useToken();
  const classes = useStyles(); 
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);

  const login = async (email, password) => {
    try {
      setLoading(true);
      
      const body = {
        usuario: email, 
        clave: password
      }
      // const resp = await Services.post3("iniciar-sesion/admin", body); // para pruebas
      const resp = await Services.post3("iniciar-sesion/", body);
      
      if( resp.status !== 200 ) {
        setError("Usuario invdálido");
        return
      }

      setToken2(resp?.data?.detalle?.authKey)

      const userId = resp?.data?.detalle?.id;
      const miStorage = window.localStorage;

      miStorage.setItem("usuarioChekcar", JSON.stringify(resp?.data?.detalle))

      const userDoc = await Services.getDocumentById("users", userId.toString()); 

      if(!userDoc.exists) {
        await Services.addDocById("users", userId.toString(),  { tokens: [] });
      }
      setToken(resp?.data?.detalle?.authKey)
      setUserAuth(userId);
      history.push("home/"+userId);

    } catch (error) {
      console.log('error: ', error)
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.container}
        container
        alignItems="stretch">
        <Grid
          item
          xs={12}
          sm={6}
          container
          alignItems="center"
          justifyContent="center">
            <img className={classes.logo} src={logo}/>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          direction="column"
          alignItems="center"
          justifyContent="center">
            <LoginForm
              loading={loading}
              onSubmit={(email, password) => login(email, password)}/>
            <Footer />
        </Grid>
      </Grid>
      <Alert
        open={Boolean(error)}
        onClose={() => setError(null)}
        message={error}
        severity="error" />
    </div>
  );
};

export default LoginView;