import motorEncendido from "../../events/motorEncendido.png";
import motorApagado from "../../events/motorApagado.png";
import frenoFuerte from "../../events/frenoFuerte.png";
import excesoDeVelocidad from "../../events/excesoDeVelocidad.png";
import finRelenti from "../../events/finRelenti.png";
import inicioRelenti from "../../events/inicioRelenti.png";
import violacionVelocidad from "../../events/violacionVelocidad.png";
import sobreRpm from "../../events/sobreRpm.png";
import calibracionRpm from "../../events/calibracionRpm.png";
import calibracionOdometro from "../../events/calibracionOdometro.png";
import temperaturaAlta from "../../events/temperaturaAlta.png";
import temperaturaBaja from "../../events/temperaturaBaja.png";
import temperaturaNormal from "../../events/temperaturaNormal.png";
import mantenimiento from "../../events/mantenimiento.png";
import aceleracionSubita from "../../events/aceleracionSubita.png";
import movimiento from "../../events/movimiento.png";
import descargaCombustible from "../../events/descargaCombustible.png";
import avanceNeutro from "../../events/avanceNeutro.png";
import choque from "../../events/choque.png";
import motorEncendidoEspera from "../../events/motorEncendidoEspera.png";
import detenidoDemasiadoTiempo from "../../events/detenidoDemasiadoTiempo.png";
import recuperacionSeñalGps from "../../events/recuperacionSeñalGps.png";
import ignicionAbierta from "../../events/ignicionAbierta.png";
import colision from "../../events/colision.png";
import conexionEquipo from "../../events/conexionEquipo.png";
import desconexionDeEquipo from "../../events/desconexionDeEquipo.png";
import antenaGpsDesconectada from "../../events/antenaGpsDesconectada.png";
import bateriaInternaDesconectada from "../../events/bateriaInternaDesconectada.png";
import botonDePanico from "../../events/botonDePanico.png";
import entraRuta from "../../events/entraRuta.png";
import rupturaDeConvoy from "../../events/rupturaDeConvoy.png";
import entradaGeocerca from "../../events/entradaGeocerca.png";
import salidaRuta from "../../events/salidaRuta.png";
import desactivacionEntrada1 from "../../events/desactivacionEntrada1.png";
import activacionEntrada1 from "../../events/activacionEntrada1.png";
import desactivacionEntrada3 from "../../events/desactivacionEntrada3.png";
import aperturaDePuerta from "../../events/aperturaDePuerta.png";
import cierreDePuerta from "../../events/cierreDePuerta.png";
import vueltaRepentina from "../../events/vueltaRepentina.png";
import vigilarMovimiento from "../../events/vigilarMovimiento.png";
import googleMarker from "../../cars/red-marker.png";

const iconsEvent = {
  "Error de sensor": botonDePanico,
  "Motor Encendido": motorEncendido,
  "Batería Externa Conectada": motorEncendido,
  "Motor Apagado": motorApagado,
  "Reinicio de equipo": motorApagado,
  "Posición Actualizada": googleMarker,
  "Freno Fuerte": frenoFuerte,
  "Exceso de velocidad": excesoDeVelocidad,
  "Fin de Ralentí": finRelenti,
  "Inicia Ralentí": inicioRelenti,
  "Carga Combustible": inicioRelenti,
  "Fin de exceso de velocidad": finRelenti,
  "Inicio de exceso de velocidad": inicioRelenti,
  "Motor: Violación de velocidad de Vehículo": violacionVelocidad,
  "Sobre RPM": sobreRpm,
  "Calibración de RPM": calibracionRpm,
  "Calibración de Odómetro": calibracionOdometro,
  "Temperatura alta": temperaturaAlta,
  "Temperatura baja": temperaturaBaja,
  "Batería de vehículo baja": temperaturaBaja,
  "Temperatura normal": temperaturaNormal,
  Mantenimiento: mantenimiento,
  "Arrastre de Vehículo": mantenimiento,
  Desenganche: mantenimiento,
  Enganche: mantenimiento,
  "Desenganche de caja": mantenimiento,
  "Activación de Grua": mantenimiento,
  "Desactivación de Grua": mantenimiento,
  "Aceleración súbita": aceleracionSubita,
  Movimiento: movimiento,
  "Enganche de Caja": movimiento,
  "Descarga Combustible": descargaCombustible,
  "Avance neutro": avanceNeutro,
  Choque: choque,
  "Motor encendido, auto en espera": motorEncendidoEspera,
  "Detenido demasiado tiempo": detenidoDemasiadoTiempo,
  "Recuperación de Señal GPS": recuperacionSeñalGps,
  "Antena GPS reconectada": recuperacionSeñalGps,
  "Alerta vigilar Ignición abierta": ignicionAbierta,
  Colisión: colision,
  "Conexión de equipo": conexionEquipo,
  "Batería Interna Conectada": conexionEquipo,
  "Desconexión de equipo": desconexionDeEquipo,
  "Batería Externa Desconectada": desconexionDeEquipo,
  //"Comando RS232 recibido": motorEncendido,
  "Antena GPS desconectada": antenaGpsDesconectada,
  "Interferencia detectado": antenaGpsDesconectada,
  "Sin señal GPRS-GSM": antenaGpsDesconectada,
  "Alerta de vigilar en movimiento": vigilarMovimiento,
  "Batería Interna Desconectada": bateriaInternaDesconectada,
  "Batería externa baja": bateriaInternaDesconectada,
  "Batería interna baja": bateriaInternaDesconectada,
  "Bateria Externa Desconectada": desconexionDeEquipo,
  "Botón de pánico": botonDePanico,
  "Entrada a Ruta": entraRuta,
  "Ruptura de convoy": rupturaDeConvoy,
  "Visita a sitio de interés": entradaGeocerca,
  "Entrada a geocerca": entradaGeocerca,
  "Salida de Ruta": salidaRuta,
  "Salida de geocerca": salidaRuta,
  "Desactivación Entrada 1": desactivacionEntrada1,
  "Activación Entrada 3": activacionEntrada1,
  "Desactivación Entrada 3": desactivacionEntrada3,
  "Apertura de Puerta": aperturaDePuerta,
  "Cierre de Puerta": cierreDePuerta,
  "Vuelta repentina": vueltaRepentina,
};

export default iconsEvent;
