import React, { useEffect, useState, useRef, useMemo } from "react";
import Div100vh from "react-div-100vh";
import AppBar from "../../components/AppBar/AppBar";
import FullScreenLoaderView from "../../components/FullScreenLoaderView/FullScreenLoaderView";
import BottomSheets from "./bottomSheets";
import { useToken } from "../../hooks/useToken";
import { ArrowBack } from "@material-ui/icons";
import {
  Card,
  Grid,
  Typography,
  Button
} from "@material-ui/core";

const platform = new H.service.Platform({
  apikey: "kUsKaAJJxXkSBces1hmFjNrIPBKRjZyXezk66tCyhXk",
});

const getSvg = (color, nombre) => `<div  style="margin-top: -20px; margin-left-30px">
<center><svg width="20" height="40" viewBox="1 -10 60 78" xmlns="http://www.w3.org/2000/svg" stroke="black" fill="${color}"><path d="M53.1,48.1c3.9-5.1,6.3-11.3,6.3-18.2C59.4,13.7,46.2,0.5,30,0.5C13.8,0.5,0.6,13.7,0.6,29.9 c0,6.9,2.5,13.1,6.3,18.2C12.8,55.8,30,77.5,30,77.5S47.2,55.8,53.1,48.1z"></path></svg></center>
<div style="background-color: ${color}; color: white; border-radius: 25px; padding:1px"  > 
  <center><label style="font-size: 10px; font-family: Roboto;"> ${nombre} </label></center>
</div>
</div>`;

function sleep() {
  return new Promise((resolve) => setTimeout(resolve, 1000));
}

const MapPointsView = ({ history, location }) => {
  const { setPoints, points } = useToken();
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [point, setPoint] = useState(null);
  const [ui, setUi] = useState(null)
  const [zoom, setZoom] = useState(null)

  useEffect(() => {
    if (location?.state) {
      const H = window.H;
      const defaultLayers = platform.createDefaultLayers();
      const _map = new H.Map(mapRef.current, defaultLayers.raster.normal.map, {
        center: { lat: 21.6565042, lng: -99.333183 },
        zoom: 4.0,
        pixelRatio: window.devicePixelRatio || 1,
      });
      const _behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(_map)
      );
      const _ui = H.ui.UI.createDefault(_map, defaultLayers, "es-ES");

      _ui.getControl("mapsettings").setAlignment("top-left");
      _ui.getControl("zoom").setAlignment("top-left");
      _ui.getControl("scalebar").setVisibility(false);

      setUi(_ui)

      drawPoints(location.state, _map)
        .then(() => {
          setPoints(location.state);
          setMap(_map);
        })
        .finally(() => setLoading(false))
        .catch((e) => console.log(e));
    }
  }, [location]);

  const drawPoints = async (points = [], map) => {
    try {
      const pointsGroup = points.map((p) => {
        const bearsIcon = new H.map.DomIcon(getSvg(p.color, p.nombre));
        const location = { lat: p.lat, lng: p.lon };
        const marker = new H.map.DomMarker(location, { icon: bearsIcon });

        marker.addEventListener("tap", () => {
          map.setZoom(18);
          map.setCenter(location);
          setPoint(p);
          setOpen(true);

        });

        map.addEventListener('mapviewchangeend', function () {
            setZoom(map.getZoom())
        });

        return marker;
      });

      const group = new H.map.Group();

      group.addObjects(pointsGroup);

      map.addObject(group);

      map.getViewModel().setLookAtData({
        bounds: group.getBoundingBox(),
      });

      await sleep();

      map.setZoom(map.getZoom() - 1);
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <Div100vh>
      <AppBar title="Puntos" />
      <div style={{ position: "absolute", marginLeft: 200, zIndex: 999 }}>
        <Card
          style={{
            padding: 2,
            marginRight: 20,
            marginTop: 24,
            width: "90%",
            float: "right",
          }}
        >
          <Grid container>
            <Grid item>
              <Button startIcon={<ArrowBack />}  onClick={() => history.push({pathname: "/rastreo-continuo"})}>Rastreo</Button>
            </Grid>
          </Grid>
        </Card>
      </div>
      <div style={{ height: "94vh" }}>
        <div ref={mapRef} />
      </div>
      <FullScreenLoaderView open={loading} />
      <BottomSheets
        open={!loading && open}
        point={point}
        onClose={() => setOpen(false)}
      />
    </Div100vh>
  );
};

export default MapPointsView;
