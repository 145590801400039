import React from "react";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BuildIcon from "@material-ui/icons/Build";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import WarningIcon from "@material-ui/icons/Warning";
import GpsOffIcon from "@material-ui/icons/GpsOff";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import PortableWifiOffIcon from "@material-ui/icons/PortableWifiOff";
import SendIcon from "@material-ui/icons/Send";
import GpsNotFixedIcon from "@material-ui/icons/GpsNotFixed";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BatteryUnknownIcon from "@material-ui/icons/BatteryUnknown";
import ErrorIcon from "@material-ui/icons/Error";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import NoMeetingRoomIcon from "@material-ui/icons/NoMeetingRoom";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import PowerIcon from "@material-ui/icons/Power";
import SpeedIcon from "@material-ui/icons/Speed";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import { Avatar } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";

const iconsType = {
  "Motor Encendido": (
    <Avatar style={{ backgroundColor: "green" }}>
      <PowerSettingsNewIcon />{" "}
    </Avatar>
  ),
  "Motor Apagado": (
    <Avatar style={{ backgroundColor: "red" }}>
      <PowerSettingsNewIcon />
    </Avatar>
  ),
  "Posición Actualizada": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <LocationOnIcon />
    </Avatar>
  ),
  "I-Button insertado": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <RadioButtonCheckedIcon />
    </Avatar>
  ),
  "I-Button retirado": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <RadioButtonUncheckedIcon />
    </Avatar>
  ),
  "Freno Fuerte": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  ),
  "Exceso de velocidad": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  ),
  "Fin de Ralentí": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  ),
  "Inicia Ralentí": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  ),
  "Motor: Violación de velocidad de Vehículo": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  ),
  "Sobre RPM": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  ),
  "Calibración de RPM": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  ),
  "Calibración de Odómetro": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <LocalGasStationIcon />
    </Avatar>
  ),
  "Temperatura alta": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <WhatshotIcon />
    </Avatar>
  ),
  "Temperatura baja": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <AcUnitIcon />
    </Avatar>
  ),
  "Temperatura normal": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <CheckCircleIcon />
    </Avatar>
  ),
  Mantenimiento: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <BuildIcon />
    </Avatar>
  ),
  "Aceleración súbita": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SpeedIcon />
    </Avatar>
  ),
  Movimiento: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <DirectionsCarIcon />
    </Avatar>
  ),
  "Descarga Combustible": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <LocalGasStationIcon />
    </Avatar>
  ),
  "Avance neutro": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <DragHandleIcon />
    </Avatar>
  ),
  Choque: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <WarningIcon />
    </Avatar>
  ),
  "Motor encendido, auto en espera": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <PowerSettingsNewIcon />
    </Avatar>
  ),
  "Detenido demasiado tiempo": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <PowerSettingsNewIcon style={{ backgroundColor: "red" }} />
    </Avatar>
  ),
  "Recuperación de Señal GPS": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <GpsOffIcon />
    </Avatar>
  ),
  "Alerta vigilar Ignición abierta": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <ErrorOutlineIcon />
    </Avatar>
  ),
  Colisión: (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <WarningIcon />
    </Avatar>
  ),
  "Conexión de equipo": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SettingsEthernetIcon />
    </Avatar>
  ),
  "Desconexión de equipo": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <PortableWifiOffIcon />
    </Avatar>
  ),
  "Comando RS232 recibido": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <SendIcon />
    </Avatar>
  ),
  "Antena GPS desconectada": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <GpsNotFixedIcon />
    </Avatar>
  ),
  "Alerta de vigilar en movimiento": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <VisibilityIcon />
    </Avatar>
  ),
  "Batería Interna Desconectada": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <BatteryUnknownIcon />
    </Avatar>
  ),
  "Botón de pánico": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <ErrorIcon />
    </Avatar>
  ),
  "Entrada a Ruta": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <LinearScaleIcon />
    </Avatar>
  ),
  "Ruptura de convoy": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <BrokenImageIcon />
    </Avatar>
  ),
  "Entrada a geocerca": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <ExitToAppIcon />
    </Avatar>
  ),
  "Salida de Ruta": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <LinearScaleIcon />
    </Avatar>
  ),
  "Desactivación Entrada 1": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <NotInterestedIcon />
    </Avatar>
  ),
  "Activación Entrada 3": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <PowerIcon />
    </Avatar>
  ),
  "Desactivación Entrada 3": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <NotInterestedIcon />
    </Avatar>
  ),
  "Apertura de Puerta": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <MeetingRoomIcon />
    </Avatar>
  ),
  "Cierre de Puerta": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <NoMeetingRoomIcon />
    </Avatar>
  ),
  "Vuelta repentina": (
    <Avatar style={{ backgroundColor: "#1388ca" }}>
      <ReplayIcon />
    </Avatar>
  ),
};

export default iconsType;
