import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Grid } from '@material-ui/core';
import StreetviewIcon from '@material-ui/icons/Streetview';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ReorderIcon from '@material-ui/icons/Reorder';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const typesGeofence = {
  1: "Circular",
  2: "Poligonal",
  3: "Rectangular"
}

const ButtomSheets = ({geofence}) => {
  if(!geofence) return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "white",
        height: "22vh",
        zIndex: 999,
        overflowY: "auto",
        overflowX: "hidden"
      }}
    >
      <ListItem style={{marginTop: -10, marginBottom: -20}}>
        <ListItemIcon><StreetviewIcon style={{color: "#1388ca"}} /></ListItemIcon>
        <ListItemText primary={geofence.nombre} secondary={"Nombre"} />
        {/* <IconButton onClick={() => onClose()}><CloseIcon style={{color: "#1388ca"}}/></IconButton> */}
      </ListItem>
      <hr style={{width: "98%"}} />
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} style={{marginTop: -10, marginBottom: -20}}>
          <ListItem style={{marginTop: -10, marginBottom: -20}}>
            <ListItemIcon><CropFreeIcon style={{color: "#1388ca"}} /></ListItemIcon>
            <ListItemText primary={typesGeofence[geofence.type]} secondary={"Tipo"} />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6} style={{marginTop: -15}}>
          <ListItem>
            <ListItemIcon><ColorLensIcon style={{color: "#1388ca"}} /></ListItemIcon>  
            <ListItemText 
              primary={
                <div style={{backgroundColor: geofence.color, height: 25, width: 25, borderRadius: 20 }}></div>
              } 
              secondary="Color"
            />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6} style={{marginTop: -40}}>
          <ListItem>
            <ListItemIcon><AccessTimeIcon style={{color: "#1388ca"}} /></ListItemIcon>
            <ListItemText primary={geofence.horaInicio || "Sin hora inicio"} secondary="hora inicio" />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6} style={{marginTop: -40}}>
          <ListItem>
            <ListItemIcon><AccessTimeIcon style={{color: "#1388ca"}} /></ListItemIcon>
            <ListItemText primary={geofence.horaFin || "Sin hora fin"} secondary="hora fin" />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={12} style={{marginTop: -40, marginBottom: -20}}>
          <ListItem>
            <ListItemIcon><ReorderIcon style={{color: "#1388ca"}} /></ListItemIcon>
            <ListItemText primary={geofence.descripcion || "Sin descripción"} secondary={"Descripción"} />
          </ListItem>
        </Grid>
      </Grid>
    </div>
  )
}

export default ButtomSheets;