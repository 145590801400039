import React, { useRef, useEffect } from 'react';
import Div100vh from "react-div-100vh";
import AppBar from '../../components/AppBar/AppBar';
import { useToken } from '../../hooks/useToken';

const platform = new H.service.Platform({
  apikey: "kUsKaAJJxXkSBces1hmFjNrIPBKRjZyXezk66tCyhXk",
});

const HomeView = () => {
  const mapRef = useRef();
  
  useEffect(() => {
    const H = window.H;
    const defaultLayers = platform.createDefaultLayers();
    const _map = new H.Map(mapRef.current, defaultLayers.raster.normal.map, {
      center: { lat: 21.6565042, lng: -99.333183 },
      zoom: 4.0,
      pixelRatio: window.devicePixelRatio || 1,
    });
    const _behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(_map));
    const _ui = H.ui.UI.createDefault(_map, defaultLayers, "es-ES");

    _ui.getControl("mapsettings").setAlignment("bottom-right");
    _ui.getControl("zoom").setAlignment("bottom-right");
    _ui.getControl("scalebar").setVisibility(false);
  }, []);

  return (
    <Div100vh>
      <AppBar title="CHEKCAR" />
      <div style={{height: "94vh"}}>
        <div ref={mapRef} />
      </div>
    </Div100vh>
  )
}

export default HomeView
