import React, { useRef, useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AppBar from "../../components/AppBar/AppBar";
import Services from "../../services";
import RedMarker from "./RedMarker";
import GreenMarker from "./GreenMarker";
import FullScreenLoaderView from "../../components/FullScreenLoaderView/FullScreenLoaderView";
import BottomSheets from "./BottomSheets";
import getBubble from "./Bubble";
import { Card, Grid, Typography } from "@material-ui/core";
import moment from "moment";

const platform = new H.service.Platform({
  apikey: "kUsKaAJJxXkSBces1hmFjNrIPBKRjZyXezk66tCyhXk",
});
const now = new Date();
const utc = now.getTimezoneOffset().toString();
const hoursUtc = parseFloat(utc) / 60;

const ShareContinuosTrackingView = ({ match, history }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [ui, setUi] = useState(null);
  const [locations, setLocations] = useState([]);
  const [cars, setCars] = useState([]);
  const [car, setCar] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(1000);
  const [seconds, setSeconds] = useState(0);
  const [dataTracking, setDataTracking] = useState();
  const [idsCars, setIdsCar] = useState([]);
  const shared = match.params.sh !== undefined
  const [isShared, setIsShared] = useState(false)

  useEffect(() => {
    let mounted = true
    if(mounted && shared){
      setIsShared(true)
    }
    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    let interval;

    if (!loading) {
      interval = setInterval(() => {
        setSeconds((seconds) => (seconds === 60 ? 0 : seconds + 1));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [loading]);

  useEffect(() => {
    let interval;

    if (dataTracking && !interval) {
      const expireDateTime = moment(dataTracking.dateTime.toDate()).add(
        dataTracking.timeShare,
        "m"
      );

      interval = setInterval(() => {
        if (moment(new Date()).isAfter(expireDateTime)) {
          clearInterval(interval);

          history.push("/rastreo-continuo/terminar");
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [dataTracking]);

  useEffect(() => {
    const H = window.H;
    const defaultLayers = platform.createDefaultLayers();
    const _map = new H.Map(mapRef.current, defaultLayers.raster.normal.map, {
      center: { lat: 21.6565042, lng: -99.333183 },
      zoom: 4.0,
      pixelRatio: window.devicePixelRatio || 1,
    });
    const _behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(_map));
    const _ui = H.ui.UI.createDefault(_map, defaultLayers, "es-ES");

    _ui.getControl("mapsettings").setAlignment("top-left");
    _ui.getControl("zoom").setAlignment("top-left");
    _ui.getControl("scalebar").setVisibility(false);

    setUi(_ui);
    setMap(_map);
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted && map && match) {
      Services.getDocumentById("restreoCompartido", match.params.id).then(
        async (doc) => {
          await getDataCars(doc.data()["idUser"]);
          await searchCar(doc.data()["idsCar"]);

          setIdsCar(doc.data()["idsCar"]);
          setDataTracking(doc.data());
        }
      );
    }

    return () => {
      mounted = false;
    };
  }, [match, map]);

  const getDataCars = async (idUser) => {
    try {
      const response = await Services.post(
        "https://gps.chekcar.mx/admin/prueba",
        { usuario: idUser },
        false
      );

      setCars(response.data.map((c) => ({ ...c })));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (idsCars.length && cars.length) {
      const timerId = setInterval(() => {
        Services.post2("prueba/devices-pos-data", {
          id: idsCars.join(),
          utc: hoursUtc,
        }).then((response) => {
          map.removeObjects(map.getObjects());
          let _locations = [...locations];

          response.data.forEach((d, index) => {
            const loc = {
              lat: parseFloat(d.latitude),
              lng: parseFloat(d.longitude),
            };
            // const newLocation =
            //   (locations[index][_locations[index].length - 1].location.lat !==
            //     loc.lat &&
            //     locations[index][_locations[index].length - 1].lng !==
            //       loc.lng) ||
            //   locations[index][_locations[index].length - 1].info.ioStatus !==
            //     d.ioStatus;
            _locations[index].push({ info: d, location: loc });

            // if (locations.length === 0) {
            //   if (newLocation) {
            //     _locations[index].push({ info: d, location: loc });
            //   }
            // } else {
            //   if (newLocation && d.ioStatus[9] === "t") {
            //     _locations[index].push({ info: d, location: loc });
            //   } else if (newLocation && d.ioStatus[9] === "f") {
            //     _locations[index] = [{ info: d, location: loc }];
            //   }
            // }
          });

          setLocations(_locations);

          _locations.forEach((routes) => {
            const start = routes[0].location.lat + "," + routes[0].location.lng;
            const end =
              routes[routes.length - 1].location.lat +
              "," +
              routes[routes.length - 1].location.lng;
            let routingParameters = {
              origin: start,
              destination: end,
              routingMode: "fast",
              transportMode: "car",
              return: "polyline",
            };

            if (
              routes.length > 2 &&
              routes[routes.length - 1].info.ioStatus[9] !== "t"
            ) {
              routingParameters.via =
                new H.service.Url.MultiValueQueryParameter(
                  routes
                    .filter((r, i) => i !== 0 && i !== routes.length - 1)
                    .map((r) => r.location.lat + "," + r.location.lng)
                );
            }

            const router = platform.getRoutingService(null, 8);

            router.calculateRoute(
              routingParameters,
              (result) =>
                onResult(result, {
                  startInfo: routes[0].info,
                  endInfo: routes[routes.length - 1].info,
                }),
              (error) => console.log(error.message)
            );
          });
        });
      }, time);

      return () => clearInterval(timerId);
    }
  }, [cars, idsCars, locations]);

  const onResult = (result, info) => {
    if (result.routes.length) {
      result.routes[0].sections.forEach((section, index) => {
        const linestring = H.geo.LineString.fromFlexiblePolyline(
          section.polyline
        );
        const routeLine = new H.map.Polyline(linestring, {
          style: {
            lineWidth: 10,
            strokeColor: "rgba(0, 128, 255, 0.7)",
            lineTailCap: "arrow-tail",
            lineHeadCap: "arrow-head",
          },
        });
        const routeArrows = new H.map.Polyline(linestring, {
          style: {
            lineWidth: 10,
            fillColor: "white",
            strokeColor: "rgba(255, 255, 255, 1)",
            lineDash: [0, 2],
            lineTailCap: "arrow-tail",
            lineHeadCap: "arrow-head",
          },
        });

        let domIconEnd, image;
        console.log("endinfo: ", info.endInfo);
        let speed = parseFloat(info.endInfo.speed);
        // if (info.endInfo.NombreEvento === "-") {
        // const carOnEnd = info.endInfo.ioStatus[9] === "f" ? false : true;
        const imageName = info.startInfo.imageIcon.split("/")[3];
        image = imageName
          .substring(0, imageName.length - 4)
          .replaceAll(" ", "")
          .replace("-", "");
        // domIconEnd = carOnEnd
        //   ? GreenMarker(image, info.endInfo)
        //   : RedMarker(image, info.endInfo);
        // }
        if (speed >= 5) {
          domIconEnd = GreenMarker("Automovil", info.startInfo);
        } else {
          domIconEnd = RedMarker("Automovil", info.endInfo);
        }
        // else if (info.endInfo.NombreEvento === "Motor Apagado") {
        //   domIconEnd = RedMarker("CarOff", info.startInfo);
        // } else if (info.endInfo.NombreEvento === "Motor Encendido") {
        //   domIconEnd = GreenMarker("CarOn", info.startInfo);
        // } else {
        //   domIconEnd = RedMarker(image, info.endInfo);
        // }

        const endLocation = section.arrival.place.location;
        const endMarker = new H.map.DomMarker(endLocation, {
          icon: new H.map.DomIcon(domIconEnd),
        });
        const getEndLocation = () => endLocation;
        const getEndInfo = () => info.endInfo;
        let isOpen = open;
        endMarker.addEventListener("tap", async (evt) => {
          const bubble = await getBubble(evt, getEndLocation, getEndInfo);

          bubble.addEventListener("statechange", (evt) => {
            if (evt.target.getState() === H.ui.InfoBubble.State.CLOSED) {
            }
          });

          if (ui.getBubbles().length) {
            ui.getBubbles().forEach((bub) => ui.removeBubble(bub));
          }

          ui.addBubble(bubble);
          map.setCenter(endLocation);
          map.setZoom(18);
          setCar((_) => info.endInfo);
          isOpen = !isOpen;
          if (!isOpen) {
            if (ui.getBubbles().length) {
              ui.getBubbles().forEach((bub) => ui.removeBubble(bub));
            }
          }
          setOpen(isOpen);
        });
        map.setCenter(getEndLocation());
        endMarker.dispatchEvent("tap");

        let objs;

        if (result.routes[0].sections.length === 1) {
          objs = [routeLine, routeArrows, endMarker];
        } else {
          if (index === 0) {
            objs = [routeLine, routeArrows];
          } else if (index === result.routes[0].sections.length - 1) {
            objs = [routeLine, routeArrows, endMarker];
          } else {
            objs = [routeLine, routeArrows];
          }
        }

        map.addObjects(objs);
      });
    }

    setLoading((loading) => {
      if (loading) {
        map.setZoom(map.getZoom() - 1);
      }

      return false;
    });
    setTime(60000);
  };

  const searchCar = async (_ids) => {
    const ids = _ids.join();

    const response = await Services.post2("prueba/devices-pos-data", {
      id: ids,
      utc: hoursUtc,
    });

    let _locations = [];
    let geoPointsObjs = [];

    response.data.forEach((d, index) => {
      const loc = {
        lat: parseFloat(d.latitude),
        lng: parseFloat(d.longitude),
      };

      if (index === 0 && response.data.length === 1) {
        map.setCenter(loc);
        map.setZoom(15);
      }

      geoPointsObjs.push(new H.map.Marker(loc));
      _locations.push([{ info: d, location: loc }]);
    });

    if (geoPointsObjs.length >= 2) {
      const group = new H.map.Group();

      group.addObjects(geoPointsObjs);

      map.getViewModel().setLookAtData({
        bounds: group.getBoundingBox(),
      });
    }

    setLocations(_locations);
  };

  return (
    <Div100vh>
      <div style={{ position: "relative" }} style={{ height: "100%" }}>
        <div style={{ width: "100%" }}>
          <AppBar title="Rastreo continuo" showIconParam={false} />
          <div style={{ position: "absolute", marginLeft: 250, zIndex: 999 }}>
            <Card
              style={{
                padding: 10,
                marginRight: 20,
                marginTop: 20,
                width: "55px",
                float: "right",
              }}
            >
              <Grid container spacing={1}>
                <Grid item>
                  <AccessTimeIcon />
                </Grid>
                <Grid item>
                  <Typography align="center">{seconds}</Typography>
                </Grid>
              </Grid>
            </Card>
          </div>
          <div style={{ height: "94vh" }}>
            <div ref={mapRef} />
          </div>
        </div>
        <BottomSheets
          isShared={isShared}
          open={open}
          car={car}
          onClose={() => setOpen(false)}
          hoursUtc={hoursUtc}
        />
      </div>
      <FullScreenLoaderView open={loading} />
    </Div100vh>
  );
};

export default ShareContinuosTrackingView;
