import React, { useState, useEffect } from 'react';
import Div100vh from 'react-div-100vh';
import AppBar from '../../components/AppBar/AppBar';
import Container from '../../components/Container/Container';
import FullScreenLoaderView from '../../components/FullScreenLoaderView/FullScreenLoaderView';
import Services from '../../services';
import { ListItemIcon, TextField, Card, ListItem, ListItemText, IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

const myStorage = window.localStorage;

const PointsGroup = ({history}) => {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]); 
  const [search, setSearch] = useState("");

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      if(mounted)  {
        try { 
          const { idCliente } = JSON.parse(myStorage.getItem("usuarioChekcar"));

          const response = await Services.get2("grupo-interes?idCliente=" + idCliente);

          if(response.datos) {
            setGroups(response.datos);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    getData();

    return () => { mounted = false }
  }, []);

  const toMap = group => {
    history.push({
      pathname: '/grupos-puntos/mapa',
      state: group
    });
  }

  return (
    <Div100vh>
      <AppBar title="Grupos de puntos" />
      <Container>
        <TextField 
          size="small"
          variant="filled"
          fullWidth
          label="Buscar"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Card style={{ maxHeight: "80vh", marginTop: 20, overflowY: "auto" }}>
        {
          groups.filter(c => 
            c.model.descripcion.toUpperCase().includes(search.toUpperCase())
            || c.model.nombre.toUpperCase().includes(search.toUpperCase())
          )
          .map((g, index) => (
            <ListItem key={index} onClick={() => toMap(g)}>
              <ListItemIcon><GroupWorkIcon style={{color: g.model.color}} /></ListItemIcon>
              <ListItemText primary={g.model.nombre} secondary={g.model.descripcion || "Sin descripción"}/>
              <ListItemIcon>
                <IconButton style={{color: "#1388ca"}}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          ))
        }
        </Card>
      </Container>
      <FullScreenLoaderView open={loading} />
    </Div100vh>
  )
}

export default PointsGroup;