
import React, { useEffect, useState } from 'react'
import { IconButton, Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";
import { useToken
 } from '../../hooks/useToken';
 import MenuIcon from '@material-ui/icons/Menu';

const AppBar = ({title = "", showIconParam = null}) => {
  const history = useHistory();
  const { showDrawer, setShowDrawer } = useToken();
  const [showBack, setShowBack] = useState(false);
  const [showIcon, setShowIcon] = useState(true);

  useEffect(() => {
    if(showIconParam !== null) {
      setShowIcon(showIconParam);
    }
  }, [showIconParam])

  useEffect(() => {
    let mounted = true;
    if(mounted) checkRoute(history?.location?.pathname);
    
    return () => { mounted = false; } 
  }, []);
 
  useEffect(() => {
    const unlisten = history.listen((location) => {
      checkRoute(location?.pathname);
    });

    return () => { unlisten(); }
  }, [history]);

  const checkRoute = (pathname) => {
    setShowBack(pathname.includes("home") ? false : true)
  } 

  const toBack = () => {
    history.goBack();
  }

  return (
    <Grid container style={{color: "white", backgroundColor: "#1388ca" , height: "6vh"}}>
      <Grid item xs={3} sm={3}>
        {
          showIcon && <IconButton onClick={() => showBack ? toBack() : setShowDrawer(!showDrawer)} style={{color: "white"}}>
          { 
            showBack 
            ? <ArrowBackIcon />
            : <MenuIcon />
          }
          </IconButton>
        }
       
      </Grid>
      <Grid 
        item xs={6} sm={6} 
        style={{  
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography align="center">
          {title}
        </Typography> 
      </Grid>
      <Grid item xs={3} sm={3}></Grid>
    </Grid>
  )
}

export default AppBar
