import React, { useState, useEffect } from 'react';
import Div100vh from "react-div-100vh";
import SearchIcon  from '@material-ui/icons/Search';
import { 
  TextField, Grid, Card, ListItem, ListItemIcon,
  ListItemText, Switch, Avatar
} from '@material-ui/core';
import AppBar from '../../components/AppBar/AppBar';
import Container from '../../components/Container/Container';
import Services from '../../services';
import Alert from "../../components/Alert";
import FullScreenLoaderView from '../../components/FullScreenLoaderView/FullScreenLoaderView';
import { CompassCalibrationOutlined } from '@material-ui/icons';

const miStorage = window.localStorage;

const ContinuosTrackingView = ({history}) => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [cars, setCars] = useState([]);
  const [error, setError] = useState("");
  
  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      if(mounted) {
        try {
          const user = JSON.parse(miStorage.getItem("usuarioChekcar"));

          const response = await Services.post(
            "https://gps.chekcar.mx/admin/prueba",
            { usuario: user.id },
            false
          );
      
          setCars(response.data.map((c) => ({...c, selected: false})));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    getData();

    return () => { mounted = false }
  }, []);

  const toMap = () => {
    if(!cars.some(c => c.selected)) {
      setError("Favor de seleccionar una unidad.");
      setTimeout(() => {
        setError("");
      }, 2500);

      return; 
    }

    history.push({
      pathname: '/rastreo-continuo/mapa',
      state: cars.filter(c => c.selected)
    });
  }
 
  return (
    <Div100vh>
      <AppBar title="Rastreo continuo" />
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <TextField 
              size="small"
              variant="filled"
              fullWidth
              label="Buscar"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{marginTop: 10}}>
            <Card style={{ maxHeight: "70vh", overflowY: "auto" }}>
            {
              cars
              .filter(c => 
                c.marca.toUpperCase().includes(search.toUpperCase())
                || c.modelo.toUpperCase().includes(search.toUpperCase())
                || c.nombre.toUpperCase().includes(search.toUpperCase())
              )
              .map((car) => (
                <ListItem key={car?.id}>
                  <ListItemText primary={car.nombre} secondary={car.marca + " "  + car.modelo}/>
                  <ListItemIcon>
                    <Switch 
                      onChange={() => setCars(cars =>
                        cars.map(c => 
                          c?.id === car?.id
                          ? {
                              ...c, 
                              selected: !c.selected
                          }
                          : c
                      ))}
                      checked={car.selected}
                      color="primary"
                    />
                  </ListItemIcon>
                </ListItem>
              ))
            }
            </Card>
          </Grid>
        </Grid>
        <Avatar 
          style={{
            backgroundColor: "#1388ca", 
            cursor: "pointer", 
            position: "absolute", 
            right: 0, 
            bottom: 0, 
            marginBottom: 10, 
            marginRight: 10
          }} 
          onClick={() => toMap()}
        >
          <SearchIcon />
        </Avatar>
      </Container>
      <FullScreenLoaderView open={loading} />
      <Alert
        open={Boolean(error)}
        onClose={() => setError("")}
        severity="error"
        message={error}
      />
    </Div100vh>
  )
}

export default ContinuosTrackingView;