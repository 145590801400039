import React, { useRef, useEffect, useState } from 'react';
import Div100vh from "react-div-100vh";
import AppBar from '../../components/AppBar/AppBar';
import BottomSheets from './BottomSheets';

const platform = new H.service.Platform({
  apikey: "kUsKaAJJxXkSBces1hmFjNrIPBKRjZyXezk66tCyhXk",
});

const clusterSvgTemplate = (number, color) => '<svg xmlns="http://www.w3.org/2000/svg" height="{diameter}" width="{diameter}">' +
  '<circle cx="{radius}px" cy="{radius}px" r="{radius}px" fill="' + color +'" />' +
  '<text x="50%" y="50%" text-anchor="middle" fill="white" stroke-width="2px" dy=".3em" font-size="2em">' + number + '</text>' +
  '</svg>';

const getSvg = color => '<svg width="20" height="40" viewBox="1 -10 60 78" xmlns="http://www.w3.org/2000/svg" stroke="black" fill="'+ color +'"> \
  <path d="M53.1,48.1c3.9-5.1,6.3-11.3,6.3-18.2C59.4,13.7,46.2,0.5,30,0.5C13.8,0.5,0.6,13.7,0.6,29.9 c0,6.9,2.5,13.1,6.3,18.2C12.8,55.8,30,77.5,30,77.5S47.2,55.8,53.1,48.1z"></path> \
  </svg>';

const drawGroupPoints = (group, map) => {
  const dataPoints = [];
  
  group.asociatedValues.forEach((v) => {
    dataPoints.push(new H.clustering.DataPoint(v.lat, v.lon));
  });

  const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
    theme: {
      getClusterPresentation: (cluster) => {
        const weight = cluster.getWeight(),
        radius = weight * 10,
        diameter = radius * 2,
        svgString = clusterSvgTemplate(group.asociatedValues.length, group.model.color).replace(/\{radius\}/g, radius).replace(/\{diameter\}/g, diameter),
        clusterIcon = new H.map.Icon(svgString, {
          size: {w: diameter, h: diameter},
          anchor: {x: radius, y: radius}
        }),
        clusterMarker = new H.map.Marker(cluster.getPosition(), {
          icon: clusterIcon,

          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom()
        });

        map.setCenter(cluster.getPosition());
        map.setZoom(7);

        clusterMarker.setData(cluster);

        return clusterMarker;
      },
      getNoisePresentation: (noisePoint) => {
        const { color } = group.asociatedValues.find(
          a => a.lat.toString().substring(0, 9) === noisePoint.getPosition().lat.toString().substring(0, 9)
          && a.lon.toString().substring(0, 9) === noisePoint.getPosition().lng.toString().substring(0, 9)
        );

        const noiseIcon = new H.map.Icon(getSvg(color));
        const noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
          icon: noiseIcon,
          min: noisePoint.getMinZoom()
        });

        noiseMarker.setData(noisePoint);
        return noiseMarker;
      }
    }
  });
  
  const layer = new H.map.layer.ObjectLayer(clusteredDataProvider);

  map.addLayer(layer);
}

const MapPointsGroupsView = ({location}) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);

  useEffect(() => {
    const H = window.H;
    const defaultLayers = platform.createDefaultLayers();
    const _map = new H.Map(mapRef.current, defaultLayers.raster.normal.map, {
      center: { lat: 21.6565042, lng: -99.333183 },
      zoom: 4.0,
      pixelRatio: window.devicePixelRatio || 1,
    });
    const _behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(_map));
    const _ui = H.ui.UI.createDefault(_map, defaultLayers, "es-ES");

    _ui.getControl("mapsettings").setAlignment("top-left");
    _ui.getControl("zoom").setAlignment("top-left");
    _ui.getControl("scalebar").setVisibility(false);
    
    drawGroupPoints(location.state, _map);
    setMap(_map);
  }, []);

  return (
    <Div100vh>
      <AppBar title="Grupo de puntos" />
      <div style={{height: "94vh"}}>
        <div ref={mapRef} />
      </div>
      <BottomSheets  
        group={location.state.model}
        paramPoints={location.state.asociatedValues}
        setMap={(loc) => {
          map.setCenter({lat: loc.lat, lng: loc.lon});
          map.setZoom(18);
        }}
      />
    </Div100vh>
  )
}

export default MapPointsGroupsView;